import dayjs from 'dayjs';
import {
  Absence,
  DictionaryAutocomplete,
  MobileUnitAssignment,
  ZsdUser,
} from '@models';

export const labelMapper = (value: DictionaryAutocomplete) =>
  value?.label ?? value?.name ?? 'Bez názvu';

export const userLabelMapper = (user: ZsdUser) =>
  `${user.firstName} ${user.lastName} (${user.username})`;

export const universalUserLabelMapper = (
  user: ZsdUser | ZsdUser[] | null | undefined
) => {
  if (!user) {
    return '';
  }

  if (Array.isArray(user)) {
    return user.map(userLabelMapper).join(', ');
  }

  return userLabelMapper(user);
};

export const mobileUnitAssignmentDateLabelMapper = (
  value: MobileUnitAssignment
) => {
  const from = dayjs(value.validFrom).format('HH:mm DD. MM. YYYY');
  const to = dayjs(value.validTo).format('HH:mm DD. MM. YYYY');

  return `Od: ${from}\nDo: ${to}`;
};

export const absenceLabelMapper = (absence: Absence) => {
  const from = dayjs(absence.fromDate).format('DD. MM. YYYY');
  const to = dayjs(absence.toDate).format('DD. MM. YYYY');

  return `${absence.absenceType?.name} (${from} až ${to})`;
};

/* Mappers duplicated/used in resources/script/action/... (for exports): */

export const columnUserLabelMapper = ({ value }: { value: ZsdUser }) => {
  return value
    ? `${value.firstName} ${value.lastName} (${value.username})`
    : '';
};
columnUserLabelMapper.displayName = 'userLabelMapper';

export function durationColumnMapper({ value }: { value: number }) {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);

  return `${hours}h ${minutes}m`;
}
durationColumnMapper.displayName = 'durationColumnMapper';

export function dangerBooleanColumnMapper({ value }: { value: number }) {
  return value;
}
dangerBooleanColumnMapper.displayName = 'dangerBooleanColumnMapper';
