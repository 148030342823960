import cn from 'clsx';
import Leaflet from 'leaflet';
import React, { useContext, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Polyline, useMap } from 'react-leaflet';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TO_MAP_CONSTS } from '@composite/map/container/constants';
import { MapTechnicalObjectsContext } from '@composite/map/context/map-technical-objects-context';
import { TechnicalObject } from '@models';
import { getToLabel, getVoltageLevel } from './oracle-types-mapper';
import { TO_MAP_COLORS } from './types';

const useStyles = makeStyles(() => ({
  polyline: {
    //filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.7))',
  },
  selected: {
    filter: 'drop-shadow(0px 0px 8px rgba(0,0,200,0.9))',
  },
  hidden: {
    opacity: 0.0,
  },
  hiddenSelected: {
    opacity: 0.1,
  },
}));

const TechnicalObjectPolyline = ({
  techObject,
  isSelected,
  onClick,
}: {
  techObject: Partial<TechnicalObject>;
  isSelected?: boolean;
  onClick?:
    | ((
        e: L.LeafletMouseEvent,
        technicalObject: Partial<TechnicalObject>
      ) => void)
    | undefined;
}) => {
  const coordinates = techObject.coordinates ?? [];
  const voltageLevel = getVoltageLevel(techObject);

  const { showLabels } = useContext(MapTechnicalObjectsContext);
  const classes = useStyles();

  const map = useMap();
  const labelRef = useRef<L.Marker>(null);

  useEffect(() => {
    const updateLabel = () => {
      const zoom = map.getZoom();
      if (zoom > TO_MAP_CONSTS.labelsMinZoom && showLabels) {
        labelRef.current?.setOpacity(1);
      } else {
        labelRef.current?.setOpacity(0);
      }
    };
    map.on('zoom', updateLabel);
    updateLabel();
    return () => {
      map.off('zoom', updateLabel);
    };
  }, [map, showLabels]);

  const color = TO_MAP_COLORS?.[voltageLevel] ?? TO_MAP_COLORS.TS;

  if (coordinates.length === 0) return null;

  return (
    <>
      <Polyline
        eventHandlers={{
          click: (e) => onClick?.(e, techObject),
        }}
        className={cn(classes.polyline, { [classes.selected]: isSelected })}
        weight={isSelected ? 6 : 3}
        color={color}
        positions={(coordinates ?? []).map(
          (c) => [c.latitude, c.longitude] as [number, number]
        )}
        dashArray={techObject.underground ? [0, 15, 0] : []}
      />

      {/* Polyline below is duplicate polyline with much wider stroke, to help with selecting and also used for highlighting selected TO */}
      <Polyline
        eventHandlers={{
          click: (e) => onClick?.(e, techObject),
        }}
        className={cn(classes.polyline, {
          [classes.hidden]: !isSelected,
          [classes.hiddenSelected]: isSelected,
        })}
        weight={35}
        color={color}
        positions={(coordinates ?? []).map(
          (c) => [c.latitude, c.longitude] as [number, number]
        )}
        dashArray={techObject.underground ? [0, 15, 0] : []}
      />

      <Marker
        ref={labelRef}
        eventHandlers={{ click: (e) => onClick?.(e, techObject) }}
        position={
          [coordinates?.[0].latitude, coordinates?.[0].longitude] as [
            number,
            number
          ]
        }
        icon={Leaflet.divIcon({
          className: 'custom-div-icon',
          html: ReactDOMServer.renderToString(
            <div
              style={{
                color,
                fontSize: isSelected
                  ? TO_MAP_CONSTS.labelsFontSizeSelected
                  : TO_MAP_CONSTS.labelsFontSize,
                textShadow: isSelected ? '1px 1px black' : 'none',
                whiteSpace: 'nowrap',
              }}
            >
              {getToLabel(techObject)}
            </div>
          ),
        })}
      />
    </>
  );
};

export default TechnicalObjectPolyline;
