import React from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormContext, useFormSelector, useUpdateEffect } from '@eas/common-web';
import { DepartmentField } from '@composite/department-field/department-field';
import { Department, ZsdUser } from '@models';

export function UserDepartmentFields() {
  const { setFieldValue, editing } = useContext(FormContext);

  const values = useFormSelector((data: ZsdUser) => ({
    department: data?.department ?? undefined,
    coordinatorPrimaryDepartment:
      data?.coordinatorPrimaryDepartment ?? undefined,
    additionalDepartments: data?.additionalDepartments ?? [],
    type: data?.type,
  }));

  const isTechnician = values?.type === 'TECHNICIAN';
  const isCoordinator = values?.type === 'COORDINATOR';

  // Remove selected department from additional departments
  useUpdateEffect(() => {
    if (values.additionalDepartments) {
      setFieldValue(
        'additionalDepartments',
        values.additionalDepartments.filter(
          (dep: Department) =>
            dep.id !== values.department?.id &&
            dep.id !== values.coordinatorPrimaryDepartment?.id
        )
      );
    }
  }, [values.department, values.coordinatorPrimaryDepartment]);

  // Reset selected departments if user type changes
  useUpdateEffect(() => {
    if (!isTechnician) {
      setFieldValue('department', null);
    }
    if (!isCoordinator) {
      setFieldValue('coordinatorPrimaryDepartment', null);
    }
    if (!isTechnician || !isCoordinator) {
      setFieldValue('additionalDepartments', []);
    }
  }, [isTechnician, isCoordinator]);

  if (!isTechnician && !isCoordinator) {
    return <></>;
  }

  return (
    <>
      <DepartmentField
        fieldName={isTechnician ? 'department' : 'coordinatorPrimaryDepartment'}
        label={
          <FormattedMessage
            id="ZSD__DEPARTMENT__FIELD_LABEL__DEPARTMENT"
            defaultMessage="Pracovisko"
          />
        }
        disabled={!editing}
        multiple={false}
        required={isCoordinator}
      />
      <DepartmentField
        fieldName="additionalDepartments"
        label={
          <FormattedMessage
            id="ZSD__DEPARTMENT__FIELD_LABEL__ADDITIONAL_DEPARTMENTS"
            defaultMessage="Ďalšie pracoviská"
          />
        }
        disabled={!editing}
        multiple={true}
        excludeId={values.department?.id}
      />
    </>
  );
}
