import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormContext,
  FormCustomField,
  FormLocalDateTimeField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { WorkOrder, WorkOrderStateChange } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { formatSecondsToHoursMinutes } from '@utils';

const getWorkedTimeFromHistoryStates = (
  stateChanges: WorkOrderStateChange[] = []
): string => {
  let lastStateChange: WorkOrderStateChange | null = null;
  let time = 0;

  for (const stateChange of stateChanges) {
    if (lastStateChange?.state.code === 'VYK') {
      time +=
        new Date(stateChange.validFrom).getTime() -
        new Date(lastStateChange.validFrom).getTime();
    }
    lastStateChange = stateChange;
  }

  return formatSecondsToHoursMinutes(time / 1000);
};

const getTraveledTimeFromHistoryStates = (
  stateChanges: WorkOrderStateChange[] = []
): string => {
  let lastStateChange: WorkOrderStateChange | null = null;
  let time = 0;

  for (const stateChange of stateChanges) {
    if (lastStateChange?.state.code === 'CES') {
      time +=
        new Date(stateChange.validFrom).getTime() -
        new Date(lastStateChange.validFrom).getTime();
    }
    lastStateChange = stateChange;
  }

  return formatSecondsToHoursMinutes(time / 1000);
};

export function TimeFields() {
  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const { id } = useFormSelector((data: WorkOrder) => ({
    id: data.id,
  }));

  useEffect(() => {
    setFieldValue(
      'durationFormatted',
      formatSecondsToHoursMinutes(getFieldValues().duration)
    );
    setFieldValue(
      'durationSapFormatted',
      formatSecondsToHoursMinutes(getFieldValues().durationSap)
    );
    setFieldValue(
      'durationWorked',
      getWorkedTimeFromHistoryStates(getFieldValues().historyStates)
    );
    setFieldValue(
      'durationTraveled',
      getTraveledTimeFromHistoryStates(getFieldValues().historyStates)
    );
    setFieldValue(
      'travelPreviousFormatted',
      formatSecondsToHoursMinutes(getFieldValues().travelPrevious ?? 0)
    );
    setFieldValue(
      'travelNextFormatted',
      formatSecondsToHoursMinutes(getFieldValues().travelNext ?? 0)
    );
  }, [setFieldValue, id, getFieldValues]);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__TIME"
            defaultMessage="Časové údaje"
          />
        }
      >
        <FormLocalDateTimeField
          name="startTime"
          disabled={true}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__START_TIME"
              defaultMessage="Termín realizácie od"
            />
          }
        />
        <FormLocalDateTimeField
          name="endTime"
          disabled={true}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__END_TIME"
              defaultMessage="Termín realizácie do"
            />
          }
        />

        <FormTextField
          name="durationFormatted"
          disabled
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DURATION"
              defaultMessage="Trvanie"
            />
          }
        />

        <FormTextField
          name="durationSapFormatted"
          disabled
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DURATION_SAP"
              defaultMessage="Trvanie (SAP)"
            />
          }
        />

        <FormTextField
          name="durationWorked"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DURATION_WORKED"
              defaultMessage="Odpracovaná doba"
            />
          }
          disabled
        />
        <FormTextField
          name="durationTraveled"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DURATION_TRAVELED"
              defaultMessage="Doba prejazdu"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="earlyStart"
          disabled
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EARLY_START"
              defaultMessage="Termín stretnutia od"
            />
          }
        />
        <FormLocalDateTimeField
          name="dueDate"
          disabled
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DUE_DATE"
              defaultMessage="Termín stretnutia do"
            />
          }
        />
        <FormLocalDateTimeField
          name="lateStart"
          disabled
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__LATE_START"
              defaultMessage="Začať plánovať najneskôr od"
            />
          }
        />
        <FormTextField
          name="travelPreviousFormatted"
          disabled={true}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TRAVEL_PREVIOUS"
              defaultMessage="Prejazd k predchádzajúcemu"
            />
          }
        />
        <FormTextField
          name="travelNextFormatted"
          disabled={true}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TRAVEL_NEXT"
              defaultMessage="Prejazd k nadchádzajúcemu"
            />
          }
        />
        <FormCustomField
          name="previousOperation"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__PREVIOUS_OPERATION"
              defaultMessage="Predchádzajúca operácia"
            />
          }
          disabled
        >
          {getFieldValues().previousOperation && (
            <>
              <div style={{ marginRight: '10px' }}>
                {getFieldValues().previousOperation.workOrderId}
              </div>
              <Redirect
                url={EvidenceBrowserUrl.WORK_ORDERS}
                id={getFieldValues().previousOperation.id}
              />
            </>
          )}
        </FormCustomField>
        <FormCustomField
          name="nextOperation"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__NEXT_OPERATION"
              defaultMessage="Nasledujúca operácia"
            />
          }
          disabled
        >
          {getFieldValues().nextOperation && (
            <>
              <div style={{ marginRight: '10px' }}>
                {getFieldValues().nextOperation.workOrderId}
              </div>
              <Redirect
                url={EvidenceBrowserUrl.WORK_ORDERS}
                id={getFieldValues().nextOperation.id}
              />
            </>
          )}
        </FormCustomField>
      </FormPanel>
    </>
  );
}
