import * as Yup from 'yup';
import { Department, MobileUnit } from '@models';

export function useValidationSchema() {
  return Yup.object<MobileUnit>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    department: Yup.mixed<Department>().nullable().required('Povinné pole'),
    startingPoint: Yup.object({
      latitude: Yup.mixed().test(
        'valid',
        'Neplatná hodnota, požadovaný formát xx.xxxxxx',
        (value) => {
          // must have dd.dddddd format
          return /^-?\d{1,2}\.\d{6,10}$/.test(value);
        }
      ),
      longitude: Yup.mixed().test(
        'valid',
        'Neplatná hodnota, požadovaný formát xx.xxxxxx',
        (value) => {
          // must have dd.dddddd format
          return /^-?\d{1,3}\.\d{6,10}$/.test(value);
        }
      ),
    }).nullable(),
  }) as Yup.Schema<MobileUnit>;
}
