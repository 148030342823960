import dayjs from 'dayjs';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Typography from '@material-ui/core/Typography';
import MuiClearIcon from '@material-ui/icons/Clear';
import MuiSearchIcon from '@material-ui/icons/Search';
import {
  Checkbox,
  CheckboxGroup,
  Form,
  formFieldFactory,
} from '@eas/common-web';
import {
  useMobileUnitList,
  useMobileUnitListEmergency,
} from '@modules/mobile-unit/mobile-unit-api';
import {
  useTechnicianList,
  useTechnicianListEmergency,
} from '@modules/user/user-api';
import { formTextFieldFactory } from '@components/form/simple-text-field/simple-text-field';
import { MobileUnit, ZsdUser } from '@models';
import { CalendarDimensions } from '@enums';
import { CalendarContext } from '../../calendar-context';
import { useStyles } from './mobile-unit-dialog-styles';

interface MobileUnitDialogProps {
  defaultValue: (ZsdUser | MobileUnit)[];
}

export interface MobileUnitDialogHandle {
  getValue: () => (ZsdUser | MobileUnit)[];
}

export const MobileUnitDialog = forwardRef<
  MobileUnitDialogHandle,
  MobileUnitDialogProps
>(function MobileUnitDialog(props, ref) {
  const intl = useIntl();
  const { dataSource, settings, currentDate } = useContext(CalendarContext);

  const [value, setValue] = useState(props.defaultValue);
  const [filter, setFilter] = useState('');

  const mobileUnitsSource = useMobileUnitList({
    skip: !dataSource.departmentsSource.loaded,
    query: filter,
    departmentIds: dataSource.departmentsSource.selected?.map((d) => d.id),
  });

  const technicianSource = useTechnicianList({
    skip: !dataSource.departmentsSource.loaded,
    query: filter,
    departmentIds: dataSource.departmentsSource.selected?.map((d) => d.id),
  });

  const [emergencyMUs] = useMobileUnitListEmergency(
    dayjs.unix(currentDate).startOf('day').format('YYYY-MM-DD'),
    settings.dimension === CalendarDimensions.WEEK
      ? dayjs
          .unix(currentDate)
          .add(7, 'day')
          .startOf('day')
          .format('YYYY-MM-DD')
      : dayjs.unix(currentDate).startOf('day').format('YYYY-MM-DD')
  );
  const [emergencyTechnicians] = useTechnicianListEmergency(
    dayjs.unix(currentDate).startOf('day').format('YYYY-MM-DD'),
    settings.dimension === CalendarDimensions.WEEK
      ? dayjs
          .unix(currentDate)
          .add(7, 'day')
          .startOf('day')
          .format('YYYY-MM-DD')
      : dayjs.unix(currentDate).startOf('day').format('YYYY-MM-DD')
  );
  const emergenciesList = [
    ...(emergencyMUs?.items ?? []),
    ...(emergencyTechnicians?.items ?? []),
  ];

  const classes = useStyles();

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => value,
    }),
    [value]
  );

  const SearchField = formFieldFactory(
    formTextFieldFactory({
      label: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__FIELD_LABEL__MOBILE_UNIT_SEARCH_PLACEHOLDER',
        defaultMessage: 'Vyhľadať',
      }),
    }),
    ({ children }) => <>{children}</>
  );

  const noResults =
    technicianSource?.result?.items?.length === 0 &&
    mobileUnitsSource?.result?.items?.length === 0;

  const items = [
    ...(technicianSource?.result?.items ?? []),
    ...(mobileUnitsSource?.result?.items ?? []),
  ];

  const isCheckedAll = Array.isArray(value) && value.length === items.length;
  const isCheckedNone = Array.isArray(value) && value.length === 0;
  const isCheckedEmergency =
    Array.isArray(value) &&
    emergenciesList.length > 0 &&
    emergenciesList.every((v, index) => v === value[index]);

  return (
    <div className={classes.root}>
      <Form
        onSubmit={(values) => setFilter(values.query)}
        editing={true}
        initialValues={{ query: '' }}
      >
        {(props) => (
          <SearchField
            name="query"
            size="small"
            classes={{ root: classes.searchInput }}
            endAdornment={
              <MuiInputAdornment position="end">
                {filter && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.resetForm();
                      setFilter('');
                    }}
                  >
                    <MuiClearIcon />
                  </IconButton>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  className={classes.searchButton}
                  onClick={() => props.handleSubmit()}
                >
                  <MuiSearchIcon />
                </Button>
              </MuiInputAdornment>
            }
          />
        )}
      </Form>

      <Divider className={classes.divider} />
      <MuiFormControlLabel
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          marginLeft: 0,
        }}
        label={
          <Typography variant="body1">
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__ALL_ITEMS"
              defaultMessage="Všetky MJ / technici"
            />
          </Typography>
        }
        control={
          <Checkbox
            threeState={true}
            value={isCheckedAll ? true : isCheckedNone ? false : undefined}
            onChange={(value) => {
              if (value == true) {
                setValue(items);
              }
              if (value === false) {
                setValue([]);
              }
            }}
          />
        }
      />

      {settings.dimension === CalendarDimensions.DAY ||
        (settings.dimension === CalendarDimensions.WEEK && (
          <MuiFormControlLabel
            disabled={emergenciesList.length === 0}
            title={
              emergenciesList.length === 0
                ? 'Žiadni MJ / technici s pohotovosťou'
                : ''
            }
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              marginLeft: 0,
            }}
            label={
              <Typography variant="body1">
                <FormattedMessage
                  id="ZSD__MOBILE_UNIT__EMERGENCY_ITEMS"
                  defaultMessage="MJ / technici s pohotovostí"
                />
              </Typography>
            }
            control={
              <Checkbox
                threeState={true}
                value={
                  isCheckedEmergency ? true : isCheckedNone ? false : undefined
                } // temporary (TODO)
                onChange={(value) => {
                  if (value == true) {
                    setValue(emergenciesList);
                  }
                  if (value === false) {
                    setValue([]);
                  }
                }}
              />
            }
          />
        ))}

      <Divider className={classes.divider} />
      {noResults && (
        <div>
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__NO_RESULTS"
            defaultMessage="Žiadne výsledky"
          />
        </div>
      )}
      {items.length > 0 && (
        <CheckboxGroup
          value={value}
          onChange={(values) => setValue(values as (ZsdUser | MobileUnit)[])}
          valueIsId={false}
          source={{ items } as any}
          labelMapper={(item) => {
            if ((item as ZsdUser).firstName) {
              return `${(item as ZsdUser).firstName} ${
                (item as ZsdUser).lastName
              }`;
            }

            return (item as MobileUnit).name;
          }}
        />
      )}
    </div>
  );
});
