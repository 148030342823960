import React, { useContext, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  ApiFilterOperation,
  Params,
  PromptContext,
  PromptProvider,
  ResultDto,
  TableField,
  abortableFetch,
  useFetch,
} from '@eas/common-web';
import { TableFieldProps } from '@eas/common-web/dist/types/components/table-field/table-field-types';
import { ChangeStateMenuList } from '@modules/planner/work-order-menu/menu-items/state';
import {
  unplanWorkOrder,
  updateWorkOrder,
} from '@modules/work-order/work-order-api';
import {
  useRegisterUnplanWarningPrompt,
  useWorkOrderStateColumn,
} from '@modules/work-order/work-order-utils';
import { WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';
import { Multitask } from './types';

const useColumns = ({
  afterSubmit,
}: {
  afterSubmit: () => void;
}): TableFieldProps<WorkOrder>['columns'] => [
  { datakey: 'workOrderId', name: 'Číslo zákazky', width: 230 },

  {
    datakey: 'locked',
    name: 'Uzamknutá',
    width: 80,
    CellComponent: ({ value }) => {
      return (
        <div
          style={{
            display: 'flex',
            gap: 16,
            margin: '4px 0',
            alignItems: 'center',
          }}
        >
          {value ? 'Áno' : 'Nie'}
        </div>
      );
    },
  },
  {
    datakey: 'currentState',
    name: 'Stav',
    width: 100,
    CellComponent: function ({ value }) {
      return useWorkOrderStateColumn(value);
    },
  },
  {
    datakey: '_changeState',
    name: 'Zmeniť stav',
    width: 100,
    CellComponent: ({ rowValue }) => {
      const ref = useRef<HTMLButtonElement>(null);

      const closeMenu = () => null;
      const [open, setOpen] = useState(false);

      return (
        <div
          style={{
            display: 'flex',
            gap: 16,
            margin: '4px 0',
            alignItems: 'center',
          }}
        >
          <Button
            style={{ fontSize: '11px', padding: '4px' }}
            ref={ref}
            onClick={() => setOpen(!open)}
            variant="contained"
          >
            Zmeniť stav
          </Button>
          <Menu
            anchorEl={ref.current}
            open={open}
            onClose={() => setOpen(false)}
          >
            <ChangeStateMenuList
              workOrder={rowValue}
              setOpen={setOpen}
              closeMenu={closeMenu}
              afterSubmit={afterSubmit}
            />
          </Menu>
        </div>
      );
    },
  },
  {
    datakey: 'id',
    name: 'Detail',
    width: 80,
    CellComponent: ({ value }) => (
      <Button
        onClick={() => window.open(`/zsd/pracovne-prikazy/${value}`, '_blank')}
      >
        <LaunchIcon fontSize="small" />
      </Button>
    ),
  },
];

const updateWorkOrderLockState = async (
  id: string,
  forceLockState: 'lock' | 'unlock'
) => {
  const latest = (await abortableFetch(
    `${EvidenceApiUrl.WORK_ORDERS}/${id}`
  ).json()) as WorkOrder;

  if (latest) {
    return updateWorkOrder(id, {
      ...latest,
      locked: forceLockState === 'lock',
    });
  }
};

const handleBulkLockWorkOrder = async (
  ids: string[],
  forceState: 'lock' | 'unlock',
  afterSubmit: () => void
) => {
  try {
    //TODO: change this, when new endpoint for bulk lock/unlock is done
    // http://git.inqool.cz/eas/projects/zsd/-/issues/435
    const promises = ids.map((id) => {
      updateWorkOrderLockState(id, forceState);
    });
    await Promise.allSettled(promises);
  } catch (error) {
    console.log(error);
  }

  afterSubmit();
};

function hasState(codes: string[], currentState: string | undefined) {
  return codes.includes(currentState || '');
}

type Props = { multitask: Multitask; refetch: () => void; unitName: string };

export function MultitaskDetailDialog(props: Props) {
  return (
    <PromptProvider>
      <MultitaskDetailDialogInner {...props} />
    </PromptProvider>
  );
}

const useWorkOrderList = (multitask: Multitask) => {
  const params: Params = {
    filters: [
      {
        field: 'id',
        operation: ApiFilterOperation.IN,
        values: multitask.workOrderRefs.map((x) => x.id),
      },
    ],
    size: -1,
  };
  const [result, loading] = useFetch<ResultDto<WorkOrder>>(
    `${EvidenceApiUrl.WORK_ORDERS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify(params),
    }
  );
  return { result, loading };
};

function MultitaskDetailDialogInner({ multitask, refetch }: Props) {
  const columns = useColumns({ afterSubmit: refetch });

  const { result, loading } = useWorkOrderList(multitask);

  const handleUnplan = async (selectedIndexes: number[] = []) => {
    try {
      const promises = selectedIndexes
        .filter((index) => {
          if (!multitask.workOrderRefs[index].id) return false;
          const currentId = multitask.workOrderRefs[index].id;
          const freshWo = result?.items.find((wo) => wo.id === currentId);
          return !freshWo?.locked;
        })
        .map((index) => {
          return unplanWorkOrder(multitask.workOrderRefs[index].id);
        });
      await Promise.allSettled(promises);
    } catch (error) {
      console.log(error);
    }
    refetch();
  };

  const { testPrompt } = useContext(PromptContext);

  useRegisterUnplanWarningPrompt();

  return (
    <div>
      {loading && <LinearProgress />}
      <TableField
        columns={columns}
        onChange={() => {}}
        value={result?.items ?? multitask.workOrderRefs ?? []}
        visibleEdit={false}
        visibleAdd={false}
        visibleRemove={false}
        visibleActionsColumn={true}
        showToolbar={true}
        showRadioCond={() => false}
        showCheckboxCond={() => true}
        ToolbarComponent={({ selectedIndexes }) => {
          const unplanDisabled = (selectedIndexes ?? []).some(
            (index) =>
              !hasState(
                ['NAP', 'ODL', 'ODM'],
                multitask.workOrderRefs[index].currentState?.code
              )
          );
          return (
            <div
              style={{
                display: 'flex',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'flex-end',
                margin: '8px 0',
              }}
            >
              <Button
                onClick={() => {
                  testPrompt({
                    key: 'MULTITASK_UNPLAN_ALERT',
                    submitCallback: async () => {
                      handleUnplan(selectedIndexes);
                    },
                  });
                }}
                variant="contained"
                color="primary"
                style={{ fontSize: '12px', padding: '4px 8px' }}
                disabled={
                  loading ||
                  !selectedIndexes ||
                  selectedIndexes.length === 0 ||
                  unplanDisabled
                }
              >
                Odplánovať označené
              </Button>
              <Button
                style={{ fontSize: '12px', padding: '4px 8px' }}
                color="primary"
                variant="contained"
                onClick={() =>
                  selectedIndexes &&
                  handleBulkLockWorkOrder(
                    selectedIndexes
                      .filter((i) => !multitask.workOrderRefs[i].locked)
                      .map((i) => multitask.workOrderRefs[i].id),
                    'lock',
                    refetch
                  )
                }
                disabled={
                  loading || !selectedIndexes || selectedIndexes.length === 0
                }
              >
                Zamknúť označené
              </Button>
              <Button
                style={{ fontSize: '12px', padding: '4px 8px' }}
                color="primary"
                variant="contained"
                onClick={() =>
                  selectedIndexes &&
                  handleBulkLockWorkOrder(
                    selectedIndexes
                      .filter((i) => multitask.workOrderRefs[i].locked)
                      .map((i) => multitask.workOrderRefs[i].id),
                    'unlock',
                    refetch
                  )
                }
                disabled={
                  loading || !selectedIndexes || selectedIndexes.length === 0
                }
              >
                Odomknúť označené
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
}
