import React from 'react';
import { TechnicalObject } from '@models';
import { SVGMarker } from '../svg-marker';
import { getTechnicalObjectType, getToLabel } from './oracle-types-mapper';
import { TechnicalObjectsSVG } from './technical-object-svg-definitions';
import { TechnicalObjectSVGMarkersListType } from './types';

const getTechnicalObjectSVG = (techObject: Partial<TechnicalObject>) => {
  const type = getTechnicalObjectType(techObject);

  if (!type) return TechnicalObjectsSVG.UNKNOWN;

  if (type.includes('EST_')) {
    return TechnicalObjectsSVG.EST;
  }
  const svg = TechnicalObjectsSVG[type as TechnicalObjectSVGMarkersListType];

  return svg || TechnicalObjectsSVG.UNKNOWN;
};

const TechnicalObjectSvgMarker = ({
  techObject,
  isSelected,
  onClick,
}: {
  techObject: Partial<TechnicalObject>;
  isSelected?: boolean;
  onClick?:
    | ((
        e: L.LeafletMouseEvent,
        technicalObject: Partial<TechnicalObject>
      ) => void)
    | undefined;
}) => {
  const coordinates = techObject.coordinates ?? [];
  const svg = getTechnicalObjectSVG(techObject);

  if (coordinates.length === 0) return null;

  return (
    <>
      {isSelected && (
        <SVGMarker
          key={`svg-selected-overlay-${techObject.fid}`}
          onClick={(e) => {
            onClick?.(e, techObject);
          }}
          svg={{
            ...svg,
            label: getToLabel(techObject),
            style: { ...svg.style, strokeWidth: 40, opacity: 0.08 },
          }}
          position={
            [coordinates?.[0].latitude, coordinates?.[0].longitude] as [
              number,
              number
            ]
          }
        />
      )}
      <SVGMarker
        key={`svg-${techObject.fid}-${isSelected}`}
        selected={isSelected}
        onClick={(e) => {
          onClick?.(e, techObject);
        }}
        svg={{
          ...svg,
          label: getToLabel(techObject),
        }}
        position={
          [coordinates?.[0].latitude, coordinates?.[0].longitude] as [
            number,
            number
          ]
        }
      />
    </>
  );
};

export default TechnicalObjectSvgMarker;
