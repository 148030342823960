import * as Yup from 'yup';
import { ZsdUser } from '@models';

export function useValidationSchema() {
  return Yup.object<ZsdUser>().shape({
    hrId: Yup.mixed().required('Povinné pole'),
    firstName: Yup.string().required('Povinné pole'),
    lastName: Yup.string().required('Povinné pole'),
    username: Yup.string().required('Povinné pole'),
    kid: Yup.string().required('Povinné pole'),
    type: Yup.mixed<ZsdUser['type']>().required('Povinné pole'),
    roles: Yup.array().required('Povinné pole'),
    coordinatorPrimaryDepartment: Yup.mixed()
      .nullable()
      .when('type', {
        is: (value) => value === 'COORDINATOR',
        then: Yup.mixed().required('Povinné pole'),
      }),
    startingPoint: Yup.object({
      latitude: Yup.mixed().test(
        'valid',
        'Neplatná hodnota, požadovaný formát xx.xxxxxx',
        (value) => {
          // must have dd.dddddd format
          return /^-?\d{1,2}\.\d{6,16}$/.test(value);
        }
      ),
      longitude: Yup.mixed().test(
        'valid',
        'Neplatná hodnota, požadovaný formát xx.xxxxxx',
        (value) => {
          // must have dd.dddddd format
          return /^-?\d{1,3}\.\d{6,16}$/.test(value);
        }
      ),
    }).nullable(),
  }) as Yup.ObjectSchema<ZsdUser>;
}
