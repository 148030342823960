export enum ExportTag {
  /* Pracovní příkazy */
  WORK_ORDERS = 'WORK_ORDER',

  /* Default */
  FILLED_FORMS_DETAIL = 'FILLED_FORMS_DETAIL',
  FILLED_FORMS_GRID = 'FILLED_FORMS_GRID',
  FORM_TEMPLATES_DETAIL = 'FORM_TEMPLATES_DETAIL',
  FORM_TEMPLATES_GRID = 'FORM_TEMPLATES_GRID',

  INTERNAL = 'INTERNAL',
  EXPORT_REQUESTS_DETAIL = 'EXPORT_REQUESTS_DETAIL',
  EXPORT_REQUESTS_GRID = 'EXPORT_REQUESTS_GRID',
  EXPORT_TEMPLATES_DETAIL = 'EXPORT_TEMPLATES_DETAIL',
  EXPORT_TEMPLATES_GRID = 'EXPORT_TEMPLATES_GRID',
}
