import * as Yup from 'yup';
import { Department, Team } from '@models';

export function useValidationSchema() {
  return Yup.object<Department>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    team: Yup.mixed<Team>().nullable().required('Povinné pole'),
    code: Yup.string().nullable().required('Povinné pole'),
  }) as Yup.Schema<Department>;
}
