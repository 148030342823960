import {
  ApiFilterOperation,
  Filter,
  ListSource,
  abortableFetch,
  useAutocompleteSource,
  useFetch,
  useScrollableSource,
} from '@eas/common-web';
import {
  MobileUnit,
  MobileUnitAssignment,
  MobileUnitAssignmentValidated,
  MobileUnitAutocomplete,
  ZsdUser,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export function useMobileUnits() {
  return useAutocompleteSource<MobileUnitAutocomplete>({
    url: `${EvidenceApiUrl.MOBILE_UNITS}/autocomplete`,
  });
}

export function deactivateMobileUnit(mu?: MobileUnit) {
  return abortableFetch(`${EvidenceApiUrl.MOBILE_UNITS}/${mu?.id}/deactivate`, {
    method: 'PUT',
  });
}

export function activateMobileUnit(mu?: MobileUnit) {
  return abortableFetch(`${EvidenceApiUrl.MOBILE_UNITS}/${mu?.id}/activate`, {
    method: 'PUT',
  });
}

export function useMobileUnitList({
  skip,
  query,
  departmentIds,
  mobileUnitIds,
}: {
  skip: boolean;
  query?: string;
  departmentIds?: string[];
  mobileUnitIds?: string[];
}) {
  const filters: Filter[] = [];

  if (departmentIds) {
    filters.push({
      field: 'department.id',
      operation: ApiFilterOperation.IN,
      values: departmentIds,
    });
  }

  if (mobileUnitIds) {
    filters.push({
      field: 'id',
      operation: ApiFilterOperation.IN,
      values: mobileUnitIds,
    });
  }

  if (query) {
    filters.push({
      field: 'name',
      operation: ApiFilterOperation.CONTAINS,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<ListSource<MobileUnit>>(
    `${EvidenceApiUrl.MOBILE_UNITS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        filters,
        sort: [
          {
            field: 'name',
            order: 'ASC',
            type: 'FIELD',
            missing: 'LAST',
          },
        ],
      }),
      skip,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useMUAssignmentList({ filters }: { filters: Filter[] }) {
  const [result, loading, reset, setLoading] = useFetch<
    ListSource<MobileUnitAssignment>
  >(`${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size: -1,
      filters,
    }),
  });

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useMUAssignmentListValidated({
  departmentId,
  date,
}: {
  departmentId: string | undefined;
  date: string | undefined;
}) {
  // get mobile units in the department
  const { result: mobileUnits } = useMobileUnitList({
    skip: !departmentId || !date,
    departmentIds: departmentId ? [departmentId] : [],
  });

  // get assignments by MU ids and date
  const mobileUnitIds = mobileUnits?.items?.map((mu) => mu.id);
  const [result, loading, reset, setLoading] = useFetch<
    ListSource<MobileUnitAssignmentValidated>
  >(`${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/list/validated`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size: -1,
      filters: [
        {
          operation: ApiFilterOperation.AND,
          filters: [
            {
              field: 'mobileUnit.id',
              operation: ApiFilterOperation.IN,
              values: mobileUnitIds,
            },
            {
              field: 'validTo',
              operation: ApiFilterOperation.GTE,
              value: date,
            },
            {
              field: 'validFrom',
              operation: ApiFilterOperation.LTE,
              value: date,
            },
          ],
        },
      ],
      sort: [
        {
          field: 'mobileUnit.name',
          order: 'ASC',
          type: 'FIELD',
        },
      ],
    }),
    skip: !departmentId || !date || !mobileUnits?.items?.length,
  });

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function updateAssignment(newAssignment: Partial<MobileUnitAssignment>) {
  return abortableFetch(
    `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${newAssignment.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newAssignment),
    }
  );
}

export const useTechniciansFromAssignment = (assignmentId: string) => {
  return useScrollableSource<ZsdUser>({
    url: `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${assignmentId}/technician/list`,
  });
};

export const createMUAssignmentOnDay = ({
  leadTechnician,
  technicians,
  mobileUnitId,
  date,
}: {
  leadTechnician?: ZsdUser;
  technicians?: ZsdUser[];
  mobileUnitId: Partial<MobileUnit>;
  date: string;
}) => {
  return abortableFetch(`${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/multiple`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      leadTechnician,
      technicians,
      mobileUnit: mobileUnitId,
      validFrom: date,
      validTo: date,
    }),
  });
};

export const useMobileUnitListEmergency = (
  fromDate: string,
  toDate?: string
) => {
  return useFetch<ListSource<MobileUnit>>(
    `${EvidenceApiUrl.MOBILE_UNITS}/list/emergency`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fromDate,
        toDate,
        size: -1,
      }),
    }
  );
};
