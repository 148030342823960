import React from 'react';
import {
  SVGDefinition,
  TO_MAP_COLORS,
  TechnicalObjectSVGMarkersListType,
} from './types';

const NN_PB = (
  <svg
    version="1.1"
    id="svg1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    overflow="visible"
  >
    <defs id="defs1" />
    <g id="layer1">
      <circle
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeOpacity: 1,
          strokeDasharray: 'none',
        }}
        id="path1"
        cx="64"
        cy="64"
        r="64"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeDasharray: 'none',
        }}
        d="M 104.48509,14.679723 C 74.492401,44.787548 44.499711,74.895374 14.507021,105.0032"
        id="path2"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeDasharray: 'none',
        }}
        d="M 88.769151,5.1810789 C 61.079163,32.871067 33.389175,60.561055 5.6991868,88.251043"
        id="path3"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeDasharray: 'none',
        }}
        d="M 88.769151,5.1810789 C 61.079163,32.871067 33.389175,60.561055 5.6991868,88.251043"
        id="path3-8"
        transform="matrix(0.84128556,0,0,0.83712756,-4.2668473,-4.1770073)"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeDasharray: 'none',
        }}
        d="M 88.769151,5.1810789 C 61.079163,32.871067 33.389175,60.561055 5.6991868,88.251043"
        id="path3-8-9"
        transform="matrix(0.57196536,0,0,0.56913846,-1.4615559,-1.307275)"
      />
    </g>
  </svg>
);

const NN_PO = (
  <svg viewBox="0 0 120 210" version="1.1" id="svg1" overflow="visible">
    <defs id="defs1" />
    <circle
      style={{
        fill: 'none',
        fillRule: 'evenodd',
        stroke: 'currentColor',
        strokeDasharray: 'none',
      }}
      id="path1"
      cx="60"
      cy="60"
      r="59.534885"
    />
    <path
      style={{
        fill: 'none',
        fillRule: 'evenodd',
        stroke: 'currentColor',
        strokeDasharray: 'none',
      }}
      d="m 63.498694,128.55701 c 0,42.10966 0,84.21932 0,126.32898"
      id="path2"
      transform="matrix(1,0,0,0.72956685,-3.4986921,26.043001)"
    />
  </svg>
);

const NN_SKRINA = (
  <svg overflow="visible" version="1.1" id="svg1" viewBox="0 0 100 60">
    <rect
      width="100"
      height="60"
      x="0"
      y="0"
      fill="none"
      stroke="currentColor"
    />
  </svg>
);

const NN_NASTRESNIK = (
  <svg overflow="visible" viewBox="0 0 129 129" version="1.1" id="svg1">
    <defs id="defs1" />
    <circle
      style={{
        fill: 'none',
        fillRule: 'evenodd',
        stroke: 'currentColor',
        strokeDasharray: 'none',
      }}
      id="path1"
      cx="64.5"
      cy="64.5"
      r="64"
    />
    <g id="g1" transform="translate(0.5,0.5)">
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 101.593,12.558771 C 72.005888,42.335278 42.418772,72.111787 12.831657,101.8883"
        id="path2"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 87.76654,4.6240728 C 60.076552,32.314061 32.386564,60.004049 4.6965758,87.694037"
        id="path3"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 88.769151,5.1810789 C 61.079163,32.871067 33.389175,60.561055 5.6991868,88.251043"
        id="path3-8"
        transform="matrix(0.84128556,0,0,0.83712756,-4.2668473,-4.1770073)"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 88.769151,5.1810789 C 61.079163,32.871067 33.389175,60.561055 5.6991868,88.251043"
        id="path3-8-9"
        transform="matrix(0.57196536,0,0,0.56913846,-1.4615559,-1.307275)"
      />
    </g>
    <g id="g7" transform="rotate(180,64.063567,64.13903)">
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 103.81668,13.788513 C 73.823994,43.896338 43.831304,74.004164 13.838614,104.11199"
        id="path4"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 89.54521,5.853236 C 61.33785,33.614991 33.130489,61.376747 4.9231281,89.138503"
        id="path5"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 70.410414,0.16314571 C 46.931521,23.545481 23.452627,46.927816 -0.02626622,70.310151"
        id="path6"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          stroke: 'currentColor',
          strokeDasharray: 'none',
        }}
        d="M 49.30722,1.6455599 C 33.268006,17.569376 17.228792,33.493193 1.1895787,49.41701"
        id="path7"
      />
    </g>
  </svg>
);

const NN_UZEMNENIE = (
  <svg
    viewBox="0 0 58.606781 122.09747"
    version="1.1"
    id="svg1"
    overflow="visible"
  >
    <defs id="defs1" />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="m 50.099346,113.59003 c 19.535594,0 39.071188,0 58.606784,0"
      id="path1"
      transform="translate(-50.099347,-16.699782)"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="m 61.915229,126.19363 c 11.553308,0 23.106617,0 34.659925,0"
      id="path2"
      transform="translate(-49.941801,-16.699782)"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="m 73.573567,138.79724 c 3.833598,0 7.667196,0 11.500794,0"
      id="path3"
      transform="translate(-50.020573,-16.699782)"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="m 79.402737,114.06266 c 0,-32.454292 0,-64.908585 0,-97.362878"
      id="path4"
      transform="translate(-50.099346,-16.699782)"
    />
  </svg>
);

// const VVN_PB_UNUSED = (
//   <svg viewBox="0 0 120 120" version="1.1" id="svg1">
//     <defs id="defs1" />
//     <rect
//       style={{ fill: 'none', stroke: 'currentColor' }}
//       id="rect1"
//       width="119.06941"
//       height="119.0694"
//       x="0.4652977"
//       y="0.4652977"
//     />
//   </svg>
// );

const VVN_PB = (
  <svg viewBox="0 0 120 120" version="1.1" id="svg1" overflow="visible">
    <defs id="defs1">
      <pattern
        xlinkHref="#Strips1_1.5"
        preserveAspectRatio="xMidYMid"
        id="pattern5"
        patternTransform="matrix(2.8284271,2.8284271,-2.8284271,2.8284271,-14.999996,-15.000005)"
        x="0"
        y="0"
        width="3.5"
      />
      <pattern
        patternUnits="userSpaceOnUse"
        width="2.5"
        height="1"
        patternTransform="translate(0,0) scale(2,2)"
        preserveAspectRatio="xMidYMid"
        id="Strips1_1.5"
        style={{ fill: 'currentColor' }}
      >
        <rect x="0" y="-0.5" width="1" height="2" id="rect140" />
      </pattern>
    </defs>
    <rect
      style={{ fill: 'url(#pattern5)', stroke: 'currentColor' }}
      id="rect1"
      width="119.06941"
      height="119.0694"
      x="0.4652977"
      y="0.4652977"
    />
  </svg>
);

const VN_USEKOVY_NAPAJAC = (
  <svg
    viewBox="0 0 112.13297 119.31357"
    version="1.1"
    id="svg1"
    overflow="visible"
  >
    <defs id="defs1" />
    <g id="layer1" transform="translate(-21.330802,-15.417663)">
      <rect
        style={{ fill: 'none', stroke: 'currentColor' }}
        id="rect5"
        width="102.05096"
        height="8.6288404"
        x="-48.242935"
        y="119.54464"
        transform="rotate(-31.636717)"
      />
      <path
        style={{ fill: 'none', stroke: 'currentColor' }}
        d="m -123.65508,432.10196 c 72.189698,0 144.379395,0 216.569091,0"
        id="path5"
        transform="matrix(0.40153921,-0.24738342,0.24630314,0.39978575,-32.962925,-72.435454)"
      />
      <rect
        style={{ fill: 'none', stroke: 'currentColor' }}
        id="rect16"
        width="12.918915"
        height="119.6465"
        x="-16.664972"
        y="26.281601"
        transform="matrix(-0.22714286,-0.97386145,0.88658842,0.46255915,0,0)"
        ry="0"
      />
      <path
        style={{ fill: 'none', stroke: 'currentColor' }}
        d="M 1.221192,237.88819 C 44.695625,310.26417 88.170058,382.64015 131.64449,455.01613"
        id="path16"
        transform="matrix(0.39978576,-0.24630314,0.24630314,0.39978576,-33.179755,-72.301874)"
      />
    </g>
  </svg>
);
//https://editsvgcode.com/

// ma byt 20% velkosti NN skrine
const NN_KONCOVKA = (
  <svg overflow="visible" viewBox="0 0 100 100">
    <polygon
      //points="50, 10, 90, 80, 10, 80"
      points="10, 20, 90, 20, 50, 90"
      fill="none"
      stroke="currentColor"
    />
  </svg>
);

const NN_SPOJKA = (
  <svg
    viewBox="0 0 91.216263 131.75684"
    version="1.1"
    id="svg1"
    overflow="visible"
  >
    <defs id="defs1" />
    <path
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeLinejoin: 'miter',
        strokeLinecap: 'butt',
      }}
      d="M 90.608131,65.87841 45.60813,0.87841014 0.60813041,65.87841 l 44.99999959,65 z"
      id="path20"
    />
    <path
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeLinejoin: 'miter',
        strokeLinecap: 'butt',
        strokeDasharray: '6, 6',
        strokeDashoffset: '0',
      }}
      d="M 0.71051241,65.981716 H 90.506931"
      id="path21"
    />
  </svg>
);

export const TS = (
  <svg
    overflow="visible"
    viewBox="0 0 136.08966 118.37556"
    version="1.1"
    id="svg1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs1">
      <pattern
        xlinkHref="#Strips1_10"
        preserveAspectRatio="xMidYMid"
        id="pattern2"
        patternTransform="matrix(0.6363961,-0.6363961,0.6363961,0.6363961,-7.1805957,-11.223442)"
        x="0"
        y="0"
      />
      <pattern
        patternUnits="userSpaceOnUse"
        width="11"
        height="1"
        patternTransform="translate(0,0) scale(2,2)"
        preserveAspectRatio="xMidYMid"
        id="Strips1_10"
        style={{ fill: 'currentColor' }}
      >
        <rect
          style={{ stroke: 'none' }}
          x="0"
          y="-0.5"
          width="1"
          height="2"
          id="rect176"
        />
      </pattern>
    </defs>
    <circle
      style={{ fill: 'url(#pattern2)', stroke: 'currentColor' }}
      id="path1"
      cx="68.04483"
      cy="40.799335"
      r="40.299335"
    />
    <rect
      style={{ stroke: 'currentColor', fill: 'none' }}
      id="rect2"
      width="14.178447"
      height="16.596283"
      x="60.955605"
      y="31.684742"
    />
    <path
      id="rect3"
      style={{ stroke: 'currentColor', fill: 'none' }}
      d="M -1.5457153e-7,80.827057 H 136.08966 Z"
    />
    <path
      style={{ stroke: 'currentColor', fill: 'none' }}
      d="M 0.55739425,80.827056 V 117.74224"
      id="path3"
    />
    <path
      style={{ stroke: 'currentColor', fill: 'none' }}
      d="M 135.58846,80.900226 V 118.37556"
      id="path4"
    />
  </svg>
);

const NN_MIV = (
  <svg viewBox="0 0 128 128" version="1.1" id="svg1" overflow="visible">
    <defs id="defs1" />
    <circle
      style={{ fill: 'currentColor', stroke: 'none' }}
      id="path1"
      cx="64"
      cy="64"
      r="64"
    />
    <path
      style={{ fill: '#fff', stroke: 'none' }}
      d="M 100.62498,93.562485 H 74.499995 v -2.958332 q 1.416666,-0.04167 3.624998,-0.291667 2.249999,-0.25 3.083332,-0.708333 1.291666,-0.791666 1.875,-1.791666 0.624999,-1.041666 0.624999,-2.583332 V 42.229173 H 83.083325 L 62.916666,92.604152 H 60.833334 L 41.625008,41.187507 h -0.541666 v 35.291652 q 0,5.124997 0.625,7.791663 0.666666,2.666666 1.999999,3.833332 0.916666,0.874999 3.791665,1.666666 2.874999,0.791666 3.749998,0.833333 v 2.958332 h -23.87499 v -2.958332 q 1.875,-0.166667 3.916665,-0.625 2.083333,-0.458333 3.208332,-1.375 1.458333,-1.166666 2,-3.583331 0.541666,-2.416666 0.541666,-8.08333 V 46.687504 q 0,-2.624999 -0.625,-4.291665 -0.624999,-1.666666 -1.791666,-2.708332 -1.291666,-1.124999 -3.166665,-1.666666 -1.874999,-0.541666 -3.708332,-0.624999 V 34.437509 H 47.916673 L 64.833332,78.770824 79.291659,41.687506 q 0.791667,-2.041665 1.333333,-4.249998 0.583333,-2.249999 0.625,-2.999999 h 19.291658 v 2.958333 q -1.166666,0.04167 -2.999998,0.374999 -1.791666,0.333334 -2.624999,0.625 -1.416666,0.5 -1.916666,1.666666 -0.5,1.166666 -0.5,2.541666 v 42.624982 q 0,1.458333 0.5,2.458332 0.5,1 1.916666,1.749999 0.75,0.416667 2.666665,0.791667 1.916666,0.333333 3.041662,0.375 z"
      id="text1"
      aria-label="M"
    />
  </svg>
);

const EST = (
  <svg
    viewBox="0 0 125.36342 103.62621"
    version="1.1"
    id="svg1"
    overflow="visible"
  >
    <defs id="defs1" />

    <rect
      style={{ fill: 'none', stroke: 'currentColor' }}
      id="rect1"
      width="122.36343"
      height="100.62622"
      x="1.5"
      y="1.5"
      rx="0"
      ry="0"
    />
    <rect
      style={{ fill: 'none', stroke: 'currentColor' }}
      id="rect1-9"
      width="35.053688"
      height="42.388912"
      x="15.338387"
      y="44.484497"
      rx="0"
      ry="0"
    />
    <rect
      style={{ fill: 'none', stroke: 'currentColor' }}
      id="rect1-9-4"
      width="35.053688"
      height="42.388912"
      x="76.180664"
      y="44.484497"
      rx="0"
      ry="0"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="M 9.2674062,16.844751 H 115.30681"
      id="path1"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="M 32.754963,16.96307 V 44.422787"
      id="path2"
    />
    <path
      style={{ fill: 'none', stroke: 'currentColor' }}
      d="M 93.257673,16.96307 V 44.422787"
      id="path2-9"
    />
  </svg>
);

const UNKNOWN = (
  <svg viewBox="0 0 128 128" version="1.1" id="svg1">
    <defs id="defs1" />
    <circle
      style={{ fill: 'currentColor', stroke: 'none' }}
      id="path1"
      cx="64"
      cy="64"
      r="64"
    />
  </svg>
);

const ORACLE_PODPERNY_BOD = (
  <svg viewBox="0 0 128 128" version="1.1" id="svg1">
    <defs id="defs1" />
    <circle
      style={{ fill: 'none', stroke: 'currentColor' }}
      id="path1"
      cx="64"
      cy="64"
      r="64"
    />
  </svg>
);

const SVG = {
  NN_PB,
  NN_SKRINA,
  NN_PO,
  NN_NASTRESNIK,
  NN_UZEMNENIE,
  VVN_PB,
  VN_USEKOVY_NAPAJAC,
  NN_KONCOVKA,
  NN_SPOJKA,
  NN_MIV,
  TS,
  EST,
  UNKNOWN,
  ORACLE_PODPERNY_BOD,
};

export const TechnicalObjectsSVG: Record<
  TechnicalObjectSVGMarkersListType,
  SVGDefinition
> = {
  UNKNOWN: {
    jsx: SVG.UNKNOWN,
    scale: 0.5,
    style: { color: 'black', strokeWidth: 2 },
    height: 40,
    width: 40,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    label: '',
  },
  TS_VN_ISTICE: {
    jsx: SVG.TS,
    scale: 1.2,
    style: { color: TO_MAP_COLORS.VN, strokeWidth: 2 },
    height: 130,
    width: 120,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    label: '',
  },
  TS_NN_ISTICE: {
    jsx: SVG.TS,
    scale: 1.2,
    style: { color: TO_MAP_COLORS.VN, strokeWidth: 2 },
    height: 130,
    width: 120,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    label: '',
  },
  EST: {
    jsx: SVG.EST,
    scale: 2,
    style: { color: 'black', strokeWidth: 2 },
    height: 40,
    width: 40,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    label: '',
  },
  TS_STANICA: {
    jsx: SVG.UNKNOWN,
    scale: 0.5,
    style: { color: 'black', strokeWidth: 2 },
    height: 40,
    width: 40,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    label: '',
  },
  NN_PODPERNY_BOD: {
    jsx: SVG.NN_PB,
    width: 40,
    height: 40,
    scale: 1,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    style: { color: TO_MAP_COLORS.NN, strokeWidth: 3 },
  },
  VN_PODPERNY_BOD: {
    jsx: SVG.NN_PB, // VN aj NN PB su rovnake, len ina farba
    width: 40,
    height: 40,
    scale: 1,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    style: { color: TO_MAP_COLORS.VN, strokeWidth: 3 },
  },
  NN_SKRINA: {
    jsx: SVG.NN_SKRINA,
    width: 80,
    height: 40,
    scale: 0.7,
    centerAnchorFactors: [2, 2],
    labelPosition: 'top',
    style: {
      color: TO_MAP_COLORS.NN,
      strokeWidth: 2,
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
  },
  ORACLE_PODPERNY_BOD: {
    jsx: SVG.ORACLE_PODPERNY_BOD,
    width: 40,
    height: 40,
    scale: 0.4,
    centerAnchorFactors: [2, 2],
    labelPosition: 'bottom',
    style: { color: TO_MAP_COLORS.TS, strokeWidth: 5 },
  },

  // NN_PO: {
  //   jsx: SVG.NN_PO,
  //   width: 40,
  //   height: 80,
  //   scale: 0.5,
  //   centerAnchorFactors: [2, 4],
  //   labelPosition: 'bottom',
  //   style: { color: 'red', strokeWidth: 2 },
  // },
  // NN_NASTRESNIK: {
  //   jsx: SVG.NN_NASTRESNIK,
  //   width: 40,
  //   height: 40,
  //   scale: 0.3,
  //   centerAnchorFactors: [2, 2],
  //   labelPosition: 'top',
  //   style: { color: TO_MAP_COLORS.NN, strokeWidth: 2 },
  // },
  TS_UZEMNENIE: {
    jsx: SVG.NN_UZEMNENIE,
    width: 40,
    height: 60,
    scale: 0.8,
    centerAnchorFactors: [2, 0],
    labelPosition: 'bottom',
    style: { color: TO_MAP_COLORS.GND, strokeWidth: 2 },
  },
  VVN_PODPERNY_BOD: {
    jsx: SVG.VVN_PB,
    width: 40,
    height: 40,
    scale: 1,
    centerAnchorFactors: [2, 2],
    labelPosition: 'bottom',
    style: { color: TO_MAP_COLORS.VVN, strokeWidth: 3 },
  },
  VN_USEKOVY_ODPAJAC: {
    jsx: SVG.VN_USEKOVY_NAPAJAC,
    width: 40,
    height: 40,
    scale: 1,
    centerAnchorFactors: [2, 2],
    labelPosition: 'bottom',
    style: { color: TO_MAP_COLORS.VN, strokeWidth: 1 },
  },
  // NN_KONCOVKA: {
  //   jsx: SVG.NN_KONCOVKA,
  //   width: 40,
  //   height: 40,
  //   scale: 0.5,
  //   centerAnchorFactors: [2, 2],
  //   labelPosition: 'bottom',
  //   style: { color: COLORS.NN, strokeWidth: 1 },
  // },
  // NN_SPOJKA: {
  //   jsx: SVG.NN_SPOJKA,
  //   width: 40,
  //   height: 60,
  //   scale: 0.3,
  //   centerAnchorFactors: [2, 2],
  //   labelPosition: 'bottom',
  //   style: { color: COLORS.NN, strokeWidth: 1 },
  // },
  NN_MIV: {
    jsx: SVG.NN_MIV,
    width: 40,
    height: 40,
    scale: 1,
    centerAnchorFactors: [2, 2],
    labelPosition: 'bottom',
    style: { color: TO_MAP_COLORS.NN, strokeWidth: 1 },
  },
};
