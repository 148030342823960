export const sk = {
  EAS_ACTIONS_COLUMN_SCRIPT_TYPE: 'Skriptovací jazyk',
  EAS_ACTIONS_FIELD_HELP_SCRIPT: '',
  EAS_ACTIONS_FIELD_HELP_SCRIPT_TYPE: '',
  EAS_ACTIONS_FIELD_LABEL_SCRIPT: 'Skript',
  EAS_ACTIONS_FIELD_LABEL_SCRIPT_TYPE: 'Skriptovací jazyk',
  EAS_ACTIONS_PANEL_TITLE: 'Skript',
  EAS_ACTIONS_TABLE_TITLE: 'Skripty',
  EAS_ACTION_BUTTON_MSG_SUCCESS: 'Akcia bola úspešne vykonaná.',
  EAS_ADMIN_CONSOLE_BTN_RESET: 'Zmazať',
  EAS_ADMIN_CONSOLE_BTN_SUBMIT: 'Vykonať',
  EAS_ADMIN_CONSOLE_INPUT_TITLE: 'Vstup',
  EAS_ADMIN_CONSOLE_LABEL_CONSOLE: 'Konzola',
  EAS_ADMIN_CONSOLE_LABEL_LOG: 'Log',
  EAS_ADMIN_CONSOLE_LABEL_RESULT: 'Výsledok',
  EAS_ADMIN_CONSOLE_LABEL_SCRIPT: 'Skript',
  EAS_ADMIN_CONSOLE_LABEL_SCRIPT_LANGUAGE: 'Skriptovací jazyk',
  EAS_ADMIN_CONSOLE_LABEL_USE_TRANSACTION: 'Vykonať v transakcii',
  EAS_ADMIN_CONSOLE_OUTPUT_TITLE: 'Výstup',
  EAS_ALOG_COLUMN_IP_ADDRESS: 'IP adresa',
  EAS_ALOG_COLUMN_MESSAGE: 'Správa',
  EAS_ALOG_COLUMN_MODULE: 'Modul',
  EAS_ALOG_COLUMN_SOURCE: 'Zdroj',
  EAS_ALOG_COLUMN_SOURCE_TYPE: 'Typ zdroja',
  EAS_ALOG_COLUMN_USER: 'Používateľ',
  EAS_ALOG_DETAIL_TITLE: 'Auditný log',
  EAS_ALOG_FIELD_LABEL_CREATED: 'Vytvorenie',
  EAS_ALOG_FIELD_LABEL_IP_ADDRESS: 'IP adresa',
  EAS_ALOG_FIELD_LABEL_MESSAGE: 'Správa',
  EAS_ALOG_FIELD_LABEL_MODULE: 'Modul',
  EAS_ALOG_FIELD_LABEL_SOURCE: 'Zdroj',
  EAS_ALOG_FIELD_LABEL_SOURCE_TYPE: 'Typ zdroja',
  EAS_ALOG_PANEL_GENERAL_TITLE: 'Všeobecné',
  EAS_ALOG_PANEL_TITLE: 'Udalosť',
  EAS_ALOG_TABLE_TITLE: 'Auditný log',
  EAS_APP_SETTINGS_MSG_LOAD_ERROR:
    'Chyba načítania aplikačných nastavení: {detail}',
  EAS_APP_SETTINGS_MSG_SAVE_ERROR:
    'Chyba uloženia aplikačných nastavení: {detail}',
  EAS_BODY_SOURCE_MSG_ERROR: 'Chyba načítania dát: {detail}',
  EAS_CERTIFICATES_FIELD_HELP_CONTENT: 'PFX, P12 alebo JKS súbor',
  EAS_CERTIFICATES_FIELD_HELP_PASSWORD: '',
  EAS_CERTIFICATES_FIELD_LABEL_CONTENT: 'Obsah',
  EAS_CERTIFICATES_FIELD_LABEL_PASSWORD: 'Heslo',
  EAS_CERTIFICATES_PANEL_TITLE: 'Certifikát',
  EAS_CERTIFICATES_TABLE_TITLE: 'Certifikáty',
  EAS_CRUD_SOURCE_MSG_CREATE_ERROR: 'Chyba uloženia dát: {detail}',
  EAS_CRUD_SOURCE_MSG_CREATE_SUCCESS: 'Záznam bol úspešne vytvorený.',
  EAS_CRUD_SOURCE_MSG_DELETE_ERROR: 'Chyba mazania dát: {detail}',
  EAS_CRUD_SOURCE_MSG_DELETE_SUCCESS: 'Záznam bol úspešne vymazaný.',
  EAS_CRUD_SOURCE_MSG_LOAD_ERROR: 'Chyba načítania dát: {detail}',
  EAS_CRUD_SOURCE_MSG_UPDATE_ERROR: 'Chyba uloženia dát: {detail}',
  EAS_CRUD_SOURCE_MSG_UPDATE_SUCCESS: 'Záznam bol úspešne upravený.',
  EAS_DASHBOARD_ADD_DIALOG_MSG:
    'Vyberte prvok, ktorý chcete vložiť na nástenku',
  EAS_DASHBOARD_ADD_DIALOG_TITLE: 'Pridať prvok',
  EAS_DASHBOARD_CARD_CUSTOM_REMOVE_BTN_ARIA: 'Odstrániť',
  EAS_DASHBOARD_CARD_SETTINGS_DIALOG_TITLE: 'Nastavenie prvku',
  EAS_DASHBOARD_CARD_UNIVERSAL_MSG_ERROR: 'Chyba reportu',
  EAS_DASHBOARD_CARD_UNIVERSAL_MSG_LOADING: 'Načítanie údajov',
  EAS_DASHBOARD_CARD_UNIVERSAL_MSG_UNSUPPORTED: 'Nepodporovaný report',
  EAS_DASHBOARD_CARD_UNIVERSAL_REFRESH_BTN_ARIA: 'Obnova',
  EAS_DASHBOARD_CARD_UNIVERSAL_REMOVE_BTN_ARIA: 'Odstrániť',
  EAS_DASHBOARD_CARD_UNIVERSAL_SETTINGS_BTN_ARIA: 'Nastavenia',
  EAS_DATEPICKER_BUTTON_CANCEL: 'Zrušiť',
  EAS_DATEPICKER_BUTTON_OK: 'Potvrdiť',
  EAS_DETAIL_ACTION_FINISH_ERROR: 'Chyba volania akcie: {detail}',
  EAS_DETAIL_BTN_CANCEL: 'Zrušiť',
  EAS_DETAIL_BTN_CREATE: 'Nový',
  EAS_DETAIL_BTN_DELETE: 'Zmazať',
  EAS_DETAIL_BTN_EDIT: 'Oprava',
  EAS_DETAIL_BTN_SAVE: 'Uložiť',
  EAS_DETAIL_EMPTY_CONTAINER_MSG:
    'Vyberte niektorú z <b>položiek zo zoznamu vľavo</b> {canCreate, select, true {, alebo kliknite na tlačidlo <b>NOVÝ</b>} other {}}.',
  EAS_DETAIL_LEAVE_DIALOG_TEXT: 'Skutočne chcete opustiť rozpracované zmeny?',
  EAS_DETAIL_LEAVE_DIALOG_TITLE: 'Zanechanie zmien',
  EAS_DETAIL_REMOVE_DIALOG_TEXT: 'Naozaj chcete zmazať záznam ?',
  EAS_DETAIL_REMOVE_DIALOG_TITLE: 'Varovanie',
  EAS_DETAIL_SHARE_DIALOG_COPY_BUTTON: 'Kopírovať',
  EAS_DETAIL_SHARE_DIALOG_MSG_SUCCESS: 'Odkaz ste úspešne skopírovali',
  EAS_DETAIL_SHARE_DIALOG_TEXT: 'Zdieľať s ostatnými:',
  EAS_DETAIL_SHARE_DIALOG_TITLE: 'Získať odkaz',
  EAS_DETAIL_TITLE: 'Všeobecné',
  EAS_DETAIL_TOOLTIP_CANCEL:
    'Prepne do prehliadacieho režimu a všetky zmeny budú zahodené',
  EAS_DETAIL_TOOLTIP_CREATE: 'Zapne editačný režim s prázdnym formulárom',
  EAS_DETAIL_TOOLTIP_DELETE: 'Nenávratne zmaže záznam',
  EAS_DETAIL_TOOLTIP_EDIT: 'Zapne editačný režim s otvoreným záznamom',
  EAS_DETAIL_TOOLTIP_REFRESH: 'Obnoviť dáta formulára',
  EAS_DETAIL_TOOLTIP_SAVE: 'Uloží záznam do evidencie',
  EAS_DETAIL_TOOLTIP_SHARE: 'Získať odkaz na zdieľanie',
  EAS_DETAIL_TOOLTIP_VALIDATE:
    'Skontroluje vyplnenie polí a ďalšie obmedzenia.',
  EAS_DETAIL_VALIDATION_MSG_ERROR: 'Formulár obsahuje chyby.',
  EAS_DETAIL_VALIDATION_MSG_SUCCESS: 'Formulár je v poriadku.',
  EAS_DEVTOOLS_REINDEX_BTN_REINDEX: 'Reindexovať',
  EAS_DEVTOOLS_REINDEX_MSG_ERROR: 'Chyba reindexácie: {detail}',
  EAS_DEVTOOLS_REINDEX_MSG_SUCCESS: 'Reindexácia dokončená',
  EAS_DIALOG_BTN_CANCEL: 'Zrušiť',
  EAS_DIALOG_BTN_CONFIRM: 'Potvrdiť',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_BTN_ACTIVATE: 'Aktivovať',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_BTN_DEACTIVATE: 'Deaktivovať',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_DIALOG_TEXT_ACTIVATE:
    'Záznam číselníka sa pridá do zoznamu použiteľných hodnôt v evidenciách',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_DIALOG_TEXT_DEACTIVATE:
    'Záznam číselníka sa vyradí zo zoznamu použiteľných hodnôt v evidenciách',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_DIALOG_TITLE_ACTIVATE: 'Varovanie',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_DIALOG_TITLE_DEACTIVATE: 'Varovanie',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_TOOLTIP_ACTIVATE:
    'Otvorí dialóg s potvrdením akcie',
  EAS_DICTIONARY_EVIDENCE_TOOLBAR_TOOLTIP_DEACTIVATE:
    'Otvorí dialóg s potvrdením akcie',
  EAS_EVIDENCE_COLUMN_ACTIVE: 'Aktívne',
  EAS_EVIDENCE_COLUMN_CODE: 'Kód',
  EAS_EVIDENCE_COLUMN_CREATED: 'Vytvorenie',
  EAS_EVIDENCE_COLUMN_CREATED_BY: 'Autor',
  EAS_EVIDENCE_COLUMN_NAME: 'Názov',
  EAS_EVIDENCE_COLUMN_ORDER: 'Poradie',
  EAS_EVIDENCE_COLUMN_UPDATED: 'Posledná úprava',
  EAS_EVIDENCE_COLUMN_UPDATED_BY: 'Autor úpravy',
  EAS_EVIDENCE_COLUMN_VALID_FROM: 'Platnosť od',
  EAS_EVIDENCE_COLUMN_VALID_TO: 'Platnosť do',
  EAS_EVIDENCE_FIELD_HELP_ACTIVE: '',
  EAS_EVIDENCE_FIELD_HELP_CODE: '',
  EAS_EVIDENCE_FIELD_HELP_NAME: '',
  EAS_EVIDENCE_FIELD_HELP_ORDER: '',
  EAS_EVIDENCE_FIELD_HELP_VALID_FROM: '',
  EAS_EVIDENCE_FIELD_HELP_VALID_TO: '',
  EAS_EVIDENCE_FIELD_LABEL_ACTIVE: 'Aktívne',
  EAS_EVIDENCE_FIELD_LABEL_CODE: 'Kód',
  EAS_EVIDENCE_FIELD_LABEL_CREATED: 'Vytvorenie',
  EAS_EVIDENCE_FIELD_LABEL_CREATED_BY: 'Autor',
  EAS_EVIDENCE_FIELD_LABEL_NAME: 'Názov',
  EAS_EVIDENCE_FIELD_LABEL_ORDER: 'Poradie',
  EAS_EVIDENCE_FIELD_LABEL_UPDATED: 'Posledná úprava',
  EAS_EVIDENCE_FIELD_LABEL_UPDATED_BY: 'Autor úpravy',
  EAS_EVIDENCE_FIELD_LABEL_VALID_FROM: 'Platný od',
  EAS_EVIDENCE_FIELD_LABEL_VALID_TO: 'Platný do',
  EAS_EVIDENCE_MSG_ACTIVATED_ERROR: 'Chyba volania funkcie: {detail}',
  EAS_EVIDENCE_MSG_ACTIVATED_SUCCESS: 'Záznam bol úspešne aktivovaný.',
  EAS_EVIDENCE_MSG_DEACTIVATED_ERROR: 'Chyba volania funkcie: {detail}',
  EAS_EVIDENCE_MSG_DEACTIVATED_SUCCESS: 'Záznam bol úspešne deaktivovaný.',
  EAS_EVIDENCE_TOOLBAR_TOOLTIP_NEXT: 'Zobraziť nadchádzajúci záznam',
  EAS_EVIDENCE_TOOLBAR_TOOLTIP_PREVIOUS: 'Zobraziť predchádzajúci záznam',
  EAS_EXPORT_DIALOG_BTN_DOWNLOAD: 'Stiahnuť',
  EAS_EXPORT_DIALOG_BTN_GENERATE_ASYNC: 'Zaradiť do frontu',
  EAS_EXPORT_DIALOG_BTN_GENERATE_SYNC: 'Exportovať',
  EAS_EXPORT_DIALOG_MSG_ERROR:
    'Nastala chyba pri vytváraní požiadavky na export.',
  EAS_EXPORT_DIALOG_MSG_MISSING_FORMAT_ERROR: 'Nie je vybraný formát',
  EAS_EXPORT_DIALOG_MSG_MISSING_TEMPLATE_ERROR: 'Nie je vybratá šablóna',
  EAS_EXPORT_DIALOG_MSG_SUCCESS:
    'Požiadavka na export bola zaradená do frontu.',
  EAS_EXPORT_DIALOG_MSG_SYNC_GENERATED_ERROR:
    'Nastala chyba pri generovaní exportu: {detail}.',
  EAS_EXPORT_DIALOG_MSG_SYNC_GENERATED_SUCCESS:
    'Export bol vygenerovaný. Môžete ho stiahnuť.',
  EAS_EXPORT_DIALOG_PANEL_FORMAT: 'Formát',
  EAS_EXPORT_DIALOG_PANEL_TEMPLATE: 'Šablóna',
  EAS_EXPORT_DIALOG_TITLE: 'Export',
  EAS_EXPORT_REQUESTS_COLUMN_PROCESSING_END: 'Koniec spracovania',
  EAS_EXPORT_REQUESTS_COLUMN_PROCESSING_START: 'Začiatok spracovania',
  EAS_EXPORT_REQUESTS_COLUMN_STATE: 'Stav',
  EAS_EXPORT_REQUESTS_COLUMN_SYSTEM_REQUEST: 'Systémový',
  EAS_EXPORT_REQUESTS_COLUMN_TYPE: 'Typ výstupu',
  EAS_EXPORT_REQUESTS_FIELD_HELP_CONFIGURATION: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_MESSAGE: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_PROCESSING_END: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_PROCESSING_START: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_RESULT: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_STATE: '',
  EAS_EXPORT_REQUESTS_FIELD_HELP_SYSTEM_REQUEST: 'Vykonáva sa mimo frontu',
  EAS_EXPORT_REQUESTS_FIELD_HELP_TYPE: '',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_CONFIGURATION: 'Parametre',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_MESSAGE: 'Chybová správa',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_PROCESSING_END: 'Koniec spracovania',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_PROCESSING_START: 'Začiatok spracovania',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_RESULT: 'Výstup',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_STATE: 'Stav',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_SYSTEM_REQUEST: 'Systémová požiadavka',
  EAS_EXPORT_REQUESTS_FIELD_LABEL_TYPE: 'Typ výstupu',
  EAS_EXPORT_REQUESTS_FIELD_PANEL_TITLE: 'Požadaviek na export',
  EAS_EXPORT_REQUESTS_TABLE_TITLE: 'Fronta exportu',
  EAS_EXPORT_TEMPLATES_COLUMN_DATA_PROVIDER: 'Zdroj dát',
  EAS_EXPORT_TEMPLATES_COLUMN_DESIGN_PROVIDER: 'Zdroj dizajnu',
  EAS_EXPORT_TEMPLATES_COLUMN_LABEL: 'Označenie',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_ALLOWED_TYPES: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_CONFIGURATION: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_CONTENT: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_DATA_PROVIDER: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_DESIGN_PROVIDER: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_DOCX4J_SCRIPT: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_FILE_NAME_TEMPLATE:
    'Ak nie je nastavený, použije sa názov šablóny. Podporuje templating.',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_LABEL:
    'Použije ich v zozname šablón v dialógu exportu',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_RESTRICT_BY_PERMISSION: '',
  EAS_EXPORT_TEMPLATES_FIELD_HELP_TAGS: '',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_ALLOWED_TYPES: 'Povolené formáty',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_CONFIGURATION: 'Parametre',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_CONTENT: 'Obsah',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_DATA_PROVIDER: 'Zdroj dát',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_DESIGN_PROVIDER: 'Zdroj dizajnu',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_DOCX4J_SCRIPT:
    'Skript pre generovanie docx4j exportu',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_FILE_NAME_TEMPLATE: 'Názov súboru',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_LABEL: 'Označenie',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_RESTRICT_BY_PERMISSION:
    'Obmedziť na oprávnenie',
  EAS_EXPORT_TEMPLATES_FIELD_LABEL_TAGS: 'Umiestnenie',
  EAS_EXPORT_TEMPLATES_FIELD_PANEL_TITLE: 'Šablóna exportu',
  EAS_EXPORT_TEMPLATES_TABLE_TITLE: 'Šablóny exportu',
  EAS_FETCH_MSG_ERROR: 'Chyba načítania dát: {detail}',
  EAS_FIELD_FILTER_CELL_BOOLEAN_OPTION_NOT_SELECTED: 'Nevybrané',
  EAS_FIELD_FILTER_CELL_BOOLEAN_OPTION_SELECTED: 'Vybrané',
  EAS_FIELD_FILTER_CELL_INTERVAL_OPTION_NOT_NULL: 'Vyplnené',
  EAS_FIELD_FILTER_CELL_INTERVAL_OPTION_NULL: 'Nevyplnené',
  EAS_FIELD_FILTER_CELL_INTERVAL_OPTION_RANGE: 'Rozmedzie',
  EAS_FIELD_REQUIRED: 'Povinné pole',
  EAS_FILES_MSG_ERROR: 'Chyba nahrávania súboru: {detail}',
  EAS_FILES_MSG_ERROR_FILE_TYPE:
    'Nepovolený typ súboru. Povolené hodnoty sú: {fileTypes}',
  EAS_FILES_MSG_ERROR_NOT_ALLOWED:
    'Súbor nebol nahraný pretože má nepovolenú príponu',
  EAS_FILES_MSG_ERROR_UPLOAD_SIZE:
    'Bola prekročená maximálna povolená veľkosť súboru',
  EAS_FILES_MSG_ERROR_VIRUS: 'Súbor nebol nahraný pretože obsahuje vírus',
  EAS_FILES_MSG_SUCCESS: 'Nahrávanie súboru dokončené',
  EAS_FILE_BTN_SELECT: 'Výber',
  EAS_FILE_TABLE_BTN_ADD: 'Pridať',
  EAS_FILE_TABLE_BTN_DOWNLOAD: 'Stiahnuť',
  EAS_FILE_TABLE_BTN_REMOVE: 'Zmazať',
  EAS_FILE_TABLE_COLUMN_ACTIONS: 'Akcia',
  EAS_FILE_TABLE_COLUMN_CREATED: 'Nahrané',
  EAS_FILE_TABLE_COLUMN_NAME: 'Názov',
  EAS_FILE_TABLE_MSG_ERROR_UPLOAD_COUNT:
    'Bol prekročený maximálny povolený počet súborov',
  EAS_HISTORY_COLUMN_CREATED: 'Dátum',
  EAS_HISTORY_COLUMN_CREATED_BY: 'Autor zmeny',
  EAS_HISTORY_COLUMN_DESCRIPTION: 'Poznámka',
  EAS_HISTORY_COLUMN_OPERATION: 'Typ zmeny',
  EAS_INLINE_TABLE_FIELD_TOOLBAR_BTN_ADD: 'Pridať',
  EAS_INTERNAL_SETTINGS_MSG_LOAD_ERROR: 'Chyba načítania nastavenia: {detail}',
  EAS_INTERNAL_SETTINGS_MSG_SAVE_ERROR: 'Chyba uloženia nastavení: {detail}',
  EAS_INTERNAL_SETTINGS_MSG_SAVE_SUCCES:
    'Nastavenie aplikácie bolo úspešne zmenené.',
  EAS_MENU_BTN_LOGOUT: 'Odhlásiť',
  EAS_MENU_ITEM_CONSOLE: 'Konzola',
  EAS_MENU_ITEM_DEVTOOLS: 'Vývojové nástroje',
  EAS_MENU_ITEM_REINDEX: 'Reindex',
  EAS_MENU_ITEM_SOAP_MESSAGES: 'SOAP komunikácia',
  EAS_MENU_VIEW: 'Zobrazenie',
  EAS_MENU_VIEW_DETAIL: 'Detail',
  EAS_MENU_VIEW_SPLIT: 'Delené',
  EAS_MENU_VIEW_TABLE: 'Tabuľka',
  EAS_MESSAGE_DIALOG_BTN_CLOSE: 'Zavrieť',
  EAS_NAMED_SETTINGS_MSG_CLEAR_ERROR: 'Chyba mazania nastavenia: {detail}',
  EAS_NAMED_SETTINGS_MSG_LOAD_ERROR: 'Chyba načítania nastavenia: {detail}',
  EAS_NAMED_SETTINGS_MSG_SAVE_ERROR: 'Chyba uloženia nastavení: {detail}',
  EAS_NOTIFICATIONS_COLUMN_EVENT: 'Udalosť',
  EAS_NOTIFICATIONS_COLUMN_READ: 'Prečítaná',
  EAS_NOTIFICATIONS_COLUMN_RECIPIENT: 'Príjemca',
  EAS_NOTIFICATIONS_COLUMN_SKIPPED: 'Preskočená',
  EAS_NOTIFICATIONS_COLUMN_SUBJECT: 'Predmet',
  EAS_NOTIFICATIONS_COLUMN_TYPE: 'Typ notifikácie',
  EAS_NOTIFICATIONS_DETAIL_TITLE: 'Notifikácia',
  EAS_NOTIFICATIONS_LABEL_CONTENT: 'Obsah',
  EAS_NOTIFICATIONS_LABEL_EVENT: 'Udalosť',
  EAS_NOTIFICATIONS_LABEL_READ: 'Prečítaná',
  EAS_NOTIFICATIONS_LABEL_RECIPIENT_EMAIL: 'E-mail',
  EAS_NOTIFICATIONS_LABEL_RECIPIENT_USERNAME: 'Meno',
  EAS_NOTIFICATIONS_LABEL_SKIPPED: 'Preskočená',
  EAS_NOTIFICATIONS_LABEL_SUBJECT: 'Predmet',
  EAS_NOTIFICATIONS_LABEL_TYPE: 'Typ notifikácia',
  EAS_NOTIFICATIONS_MESSAGES_PANEL_TITLE: 'Notifikácia',
  EAS_NOTIFICATIONS_RECIPIENT_PANEL_TITLE: 'Príjemca',
  EAS_NOTIFICATIONS_TABLE_TITLE: 'Notifikácia',
  EAS_NOTIFICATION_TEMAPLTES_TABLE_TITLE: 'Šablóny notifikácií',
  EAS_NOTIFICATION_TEMPLATESS_LABEL_CONTENT: 'Obsah',
  EAS_NOTIFICATION_TEMPLATESS_LABEL_EVENT: 'Udalosť',
  EAS_NOTIFICATION_TEMPLATESS_LABEL_NAME: 'Názov',
  EAS_NOTIFICATION_TEMPLATESS_LABEL_SUBJECT: 'Predmet',
  EAS_NOTIFICATION_TEMPLATESS_LABEL_TYPE: 'Typ',
  EAS_NOTIFICATION_TEMPLATES_ACTIONBAR_BTN_TEST: 'Ukážka',
  EAS_NOTIFICATION_TEMPLATES_COLUMN_EVENT: 'Udalosť',
  EAS_NOTIFICATION_TEMPLATES_COLUMN_SUBJECT: 'Predmet',
  EAS_NOTIFICATION_TEMPLATES_COLUMN_TIMER: 'Názov',
  EAS_NOTIFICATION_TEMPLATES_COLUMN_TYPE: 'Typ',
  EAS_NOTIFICATION_TEMPLATES_DETAIL_TITLE: 'Šablóna',
  EAS_NOTIFICATION_TEMPLATES_PANEL_TITLE: 'Všeobecné',
  EAS_NOTIFICATION_TEMPLATES_RULE_COLUMN_ID: 'Názov',
  EAS_NOTIFICATION_TEMPLATES_RULE_COLUMN_VALUE: 'Hodnota',
  EAS_NOTIFICATION_TEMPLATES_RULE_LABEL_ROLES: 'Role',
  EAS_NOTIFICATION_TEMPLATES_RULE_LABEL_RULES: 'Pravidlá',
  EAS_NOTIFICATION_TEMPLATES_RULE_LABEL_TYPE: 'Typ',
  EAS_NOTIFICATION_TEMPLATES_RULE_LABEL_USERS: 'Používatelia',
  EAS_NOTIFICATION_TEMPLATES_RULE_PANEL_TITLE: 'Pravidlo',
  EAS_NOTIFICATIONS_MENU_TITLE: 'Notifikácie',
  EAS_NOTIFICATIONS_MENU_ALL_READ: 'Vše prečítané',
  EAS_NOTIFICATIONS_MENU_HIDE_READ: 'Skryť prečítané',
  EAS_NOTIFICATIONS_MENU_SHOW_READ: 'Zobraziť prečítané',
  EAS_NOTIFICATIONS_MENU_MARK_ALL_READ: 'Označiť všetko za prečítané',
  EAS_PAGED_TABLE_FIELD_ROWS_PER_PAGE: 'Počet zobrazených záznamov na stránke:',
  EAS_REPORTING_DATA_ERROR_LOADING: 'Chyba načítania reportu',
  EAS_REPORTING_DATA_FIELD_CREATED: 'Generované',
  EAS_REPORTING_DEFINITIONS_ERROR_LOADING: 'Chyba načítania reportov',
  EAS_REPORTING_DEFINITIONS_PANEL_TITLE: 'Výber reportu',
  EAS_REPORTING_DETAIL_PANEL_TITLE_DATA: 'Data',
  EAS_REPORTING_DETAIL_PANEL_TITLE_PARAMETERS: 'Parametre',
  EAS_REPORTING_DETAIL_PANEL_TITLE_REPORT: 'Report {name}',
  EAS_REPORTING_INPUT_BUTTON_EXPORT: 'Exportovať',
  EAS_REPORTING_INPUT_BUTTON_GENERATE: 'Generovať',
  EAS_REPORTING_MENU_VIEW: 'Zobrazenie',
  EAS_REPORTING_MENU_VIEW_DATA: 'Report',
  EAS_REPORTING_MENU_VIEW_DEFINITIONS: 'Výber',
  EAS_REPORTING_MENU_VIEW_SPLIT: 'Delené',
  EAS_REPORTING_SPLIT_DATA: 'Report',
  EAS_REPORTING_SPLIT_DEFINITIONS: 'Výber',
  EAS_SCHEDULE_JOBS_CANCEL_DIALOG_TEXT:
    'Zastavenie bežiacej úlohy môže spôsobiť inkonzistenciu dát. Skutočne chcete zastaviť bežiacu úlohu?',
  EAS_SCHEDULE_JOBS_CANCEL_DIALOG_TITLE: 'Varovanie',
  EAS_SCHEDULE_JOBS_COLUMN_LAST: 'Posledné spustenie',
  EAS_SCHEDULE_JOBS_COLUMN_LOG_ACTION: 'Logovanie',
  EAS_SCHEDULE_JOBS_COLUMN_NEXT: 'Ďalšie spustenie',
  EAS_SCHEDULE_JOBS_COLUMN_RUNNING: 'Beží',
  EAS_SCHEDULE_JOBS_COLUMN_SCRIPT_TYPE: 'Skriptovací jazyk',
  EAS_SCHEDULE_JOBS_COLUMN_TIMER: 'Časovač',
  EAS_SCHEDULE_JOBS_DETAIL_TITLE: 'Časová úloha',
  EAS_SCHEDULE_JOBS_FIELD_HELP_CONSOLE: '',
  EAS_SCHEDULE_JOBS_FIELD_HELP_LOG_ACTION: '',
  EAS_SCHEDULE_JOBS_FIELD_HELP_RESULT: '',
  EAS_SCHEDULE_JOBS_FIELD_HELP_SCRIPT: '',
  EAS_SCHEDULE_JOBS_FIELD_HELP_SCRIPT_TYPE: '',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_DESCRIPTION: 'Popis',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_LAST: 'Posledné spustenie',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_LOG_ACTION: 'Logovanie',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_NEXT: 'Ďalšie spustenie',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_SCRIPT: 'Skript',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_SCRIPT_TYPE: 'Skriptovací jazyk',
  EAS_SCHEDULE_JOBS_FIELD_LABEL_TIMER: 'Časovač',
  EAS_SCHEDULE_JOBS_MSG_CANCEL_ERROR: 'Nastala chyba.',
  EAS_SCHEDULE_JOBS_MSG_CANCEL_SUCCESS: 'Úloha bola úspešne zrušená.',
  EAS_SCHEDULE_JOBS_MSG_START_ERROR: 'Nastala chyba.',
  EAS_SCHEDULE_JOBS_MSG_START_SUCCESS: 'Úloha bola úspešne spustená.',
  EAS_SCHEDULE_JOBS_PANEL_TITLE: 'Časová úloha',
  EAS_SCHEDULE_JOBS_START_DIALOG_TEXT: 'Skutočne chcete spustiť úlohu?',
  EAS_SCHEDULE_JOBS_START_DIALOG_TITLE: 'Varovanie',
  EAS_SCHEDULE_JOBS_TABLE_TITLE: 'Časové úlohy',
  EAS_SCHEDULE_JOBS_TOOLBAR_BTN_CANCEL: 'Zastaviť',
  EAS_SCHEDULE_JOBS_TOOLBAR_BTN_START: 'Spustiť',
  EAS_SCHEDULE_JOBS_TOOLBAR_TOOLTIP_CANCEL:
    'Otvorte dialóg pre potvrdenie zastavenia úlohy',
  EAS_SCHEDULE_JOBS_TOOLBAR_TOOLTIP_START:
    'Otvorte dialóg pre potvrdenie spustenia úlohy',
  EAS_SCHEDULE_RUNS_COLUMN_END_TIME: 'Koniec',
  EAS_SCHEDULE_RUNS_COLUMN_JOB: 'Úloha',
  EAS_SCHEDULE_RUNS_COLUMN_START_TIME: 'Začiatok',
  EAS_SCHEDULE_RUNS_COLUMN_STATE: 'Stav',
  EAS_SCHEDULE_RUNS_DETAIL_TITLE: 'Beh časovej úlohy',
  EAS_SCHEDULE_RUNS_FIELD_HELP_END_TIME: '',
  EAS_SCHEDULE_RUNS_FIELD_HELP_JOB: '',
  EAS_SCHEDULE_RUNS_FIELD_HELP_START_TIME: '',
  EAS_SCHEDULE_RUNS_FIELD_HELP_STATE: '',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_CONSOLE: 'Konzola',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_END_TIME: 'Koniec',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_JOB: 'Úloha',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_RESULT: 'Výsledok',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_START_TIME: 'Začiatok',
  EAS_SCHEDULE_RUNS_FIELD_LABEL_STATE: 'Stav',
  EAS_SCHEDULE_RUNS_PANEL_TITLE: 'Beh časovej úlohy',
  EAS_SCHEDULE_RUNS_TABLE_TITLE: 'Behy časových úloh',
  EAS_SCROLLABLE_SOURCE_MSG_ERROR: 'Chyba načítania dát: {detail}',
  EAS_SEQUENCES_COLUMN_COUNTER: 'Počítadlo',
  EAS_SEQUENCES_COLUMN_DESCRIPTION: 'Popis',
  EAS_SEQUENCES_COLUMN_FORMAT: 'Formát',
  EAS_SEQUENCES_COLUMN_LOCAL: 'Lokálne',
  EAS_SEQUENCES_DETAIL_TITLE: 'Číselný rad',
  EAS_SEQUENCES_FIELD_LABEL_COUNTER: 'Počítadlo',
  EAS_SEQUENCES_FIELD_LABEL_DESCRIPTION: 'Popis',
  EAS_SEQUENCES_FIELD_LABEL_FORMAT: 'Formát',
  EAS_SEQUENCES_FIELD_LABEL_LOCAL: 'Lokálny rad',
  EAS_SEQUENCES_PANEL_TITLE: 'Číselný rad',
  EAS_SEQUENCES_TABLE_TITLE: 'Číselné rady',
  EAS_SIGNING_CONTENTS_FIELD_HELP_SIGNED: '',
  EAS_SIGNING_CONTENTS_FIELD_HELP_TO_SIGN: '',
  EAS_SIGNING_CONTENTS_FIELD_LABEL_SIGNED: 'Podpísané',
  EAS_SIGNING_CONTENTS_FIELD_LABEL_TO_SIGN: 'Na podpis',
  EAS_SIGNING_CONTENTS__COLUMN_SIGNED: 'Podpísané',
  EAS_SIGNING_CONTENTS__COLUMN_TO_SIGN: 'Na podpísanie',
  EAS_SIGNING_REQUESTS_COLUMN_ERROR: 'Chyba',
  EAS_SIGNING_REQUESTS_COLUMN_NAME: 'Názov',
  EAS_SIGNING_REQUESTS_COLUMN_STATE: 'Stav',
  EAS_SIGNING_REQUESTS_COLUMN_USER: 'Používateľ',
  EAS_SIGNING_REQUESTS_CONTENTS_PANEL_TITLE: 'Súbory',
  EAS_SIGNING_REQUESTS_FIELD_HELP_STATE: '',
  EAS_SIGNING_REQUESTS_FIELD_LABEL_ERROR: 'Chyba',
  EAS_SIGNING_REQUESTS_FIELD_LABEL_NAME: 'Názov',
  EAS_SIGNING_REQUESTS_FIELD_LABEL_STATE: 'Stav',
  EAS_SIGNING_REQUESTS_FIELD_LABEL_USER: 'Používateľ',
  EAS_SIGNING_REQUESTS_PANEL_TITLE: 'Dokument',
  EAS_SIGNING_REQUEST_TABLE_TITLE: 'Dokumenty na podpísanie',
  EAS_SIGN_REQUESTS_SIGN_DIALOG_TEXT:
    'Skutočne chcete označiť požiadavku za podpísanú ?',
  EAS_SIGN_REQUESTS_SIGN_DIALOG_TITLE: 'Varovanie',
  EAS_SIGN_REQUESTS_TOOLBAR_BTN_SIGN: 'Podpísať',
  EAS_SIGN_REQUESTS_TOOLBAR_BTN_UPLOAD_SIGNED_CONTENT: 'Nahrať',
  EAS_SIGN_REQUESTS_TOOLBAR_TOOLTIP_SIGN:
    'Otvorte dialóg s potvrdením podpísania dokumentu',
  EAS_SIGN_REQUESTS_TOOLBAR_TOOLTIP_UPLOAD_SIGNED_CONTENT:
    'Otvorte dialóg s nahraním podpísanej prílohy',
  EAS_SIGN_REQUESTS_UPLOAD_SIGNED_CONTENT_DIALOG_TEXT:
    'Skutočne chcete označiť požiadavku za podpísanú ?',
  EAS_SIGN_REQUESTS_UPLOAD_SIGNED_CONTENT_DIALOG_TITLE: 'Varovanie',
  EAS_SOAP_LOGGER_MESSAGES_COLUMN_DURATION: 'Trvanie',
  EAS_SOAP_LOGGER_MESSAGES_COLUMN_LOCAL_PART: 'Operácia',
  EAS_SOAP_LOGGER_MESSAGES_COLUMN_SERVICE: 'Služba',
  EAS_SOAP_LOGGER_MESSAGES_DETAIL_TITLE: 'SOAP komunikácia',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_DURATION: 'Trvanie',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_ERROR: 'Chyba',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_HTTP_STATUS: 'Stavový kód HTTP',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_LOCAL_PART: 'Operácia',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_REQUEST: 'Otázka',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_RESPONSE: 'Odpoveď',
  EAS_SOAP_LOGGER_MESSAGES_LABEL_SERVICE: 'Služba',
  EAS_SOAP_LOGGER_MESSAGES_PANEL_TITLE: 'SOAP komunikácia',
  EAS_SOAP_LOGGER_MESSAGES_TABLE_TITLE: 'SOAP komunikácia',
  EAS_TABLE_BULK_ACTION_DIALOG_MSG_SUCCESS: 'Hromadná akcia prebehla úspešne.',
  EAS_TABLE_BULK_ACTION_MENU_BTN: 'Hromadné akcie',
  EAS_TABLE_BULK_DIALOG_BTN_CONFIRM: 'Vykonať',
  EAS_TABLE_BULK_DIALOG_WARNING:
    'Akcia bude aplikovaná na {count, plural, one {1 {applyToSelected, select, true {VYBRANÉ} false {VYFILTROVANÚ} } položku} few {# {applyToSelected, select, true {VYBRANÉ} položky} other {# {applyToSelected, select, true {VYBRANÝCH} false {VYFILTROVANÝCH} } položiek} }.',
  EAS_TABLE_COLUMN_DIALOG_BTN_RESET: 'Obnoviť',
  EAS_TABLE_COLUMN_DIALOG_BTN_SAVE: 'Uložiť',
  EAS_TABLE_COLUMN_DIALOG_TITLE: 'Nastavenie stĺpcov',
  EAS_TABLE_EMPTY_SOURCE: 'Zadaným filtrom nezodpovedajú žiadne záznamy.',
  EAS_TABLE_EMPTY_SOURCE_REFRESH: 'Obnoviť predvolené nastavenia filtrov',
  EAS_TABLE_EXPORT_ACTION_MENU_BTN: 'Export',
  EAS_TABLE_FIELD_BTN_SAVE: 'Uložiť',
  EAS_TABLE_FIELD_COLUMN_ORDER: 'Poradie',
  EAS_TABLE_FIELD_DIALOG_TITLE_ADD: 'Pridanie',
  EAS_TABLE_FIELD_DIALOG_TITLE_DETAIL: 'Detail',
  EAS_TABLE_FIELD_DIALOG_TITLE_EDIT: 'Úprava',
  EAS_TABLE_FIELD_EMPTY_SOURCE: 'Nie sú dostupné žiadne záznamy.',
  EAS_TABLE_FIELD_FIELD_HELP_ORDER: '',
  EAS_TABLE_FIELD_FIELD_LABEL_ORDER: 'Poradie',
  EAS_TABLE_FIELD_REMOVE_DIALOG_BTN_DELETE: 'Zmazať',
  EAS_TABLE_FIELD_REMOVE_DIALOG_MSG_CONFIRM:
    'Ste si istí, že chcete zmazať vybraný prvok ?',
  EAS_TABLE_FIELD_REMOVE_DIALOG_TITLE: 'Zmazanie',
  EAS_TABLE_FIELD_TOOLBAR_BTN_ADD: 'Pridať',
  EAS_TABLE_FIELD_TOOLBAR_BTN_COLUMN_DIALOG_OPEN: 'Nastavenie stĺpcov',
  EAS_TABLE_FIELD_TOOLBAR_BTN_DELETE: 'Zmazať',
  EAS_TABLE_FIELD_TOOLBAR_BTN_EDIT: 'Upraviť',
  EAS_TABLE_FIELD_TOOLBAR_BTN_FILTER: 'filtre',
  EAS_TABLE_FIELD_TOOLBAR_BTN_FILTER_DIALOG_OPEN: 'Nastavenie filtrov',
  EAS_TABLE_FILTER_CELL_BOOLEAN_OPTION_NOT_SELECTED: 'Nevybrané',
  EAS_TABLE_FILTER_CELL_BOOLEAN_OPTION_SELECTED: 'Vybrané',
  EAS_TABLE_FILTER_CELL_INTERVAL_FROM: 'Od',
  EAS_TABLE_FILTER_CELL_INTERVAL_OPERATION: 'Operácia',
  EAS_TABLE_FILTER_CELL_INTERVAL_TO: 'Do',
  EAS_TABLE_FILTER_CELL_SELECT_OPERATION: 'Operácia',
  EAS_TABLE_FILTER_CELL_SELECT_OPTION_EQUALS: 'Vyplnené',
  EAS_TABLE_FILTER_CELL_SELECT_OPTION_NULL: 'Nevyplnené',
  EAS_TABLE_FILTER_CELL_SELECT_VALUE: 'Hodnota',
  EAS_TABLE_FILTER_DIALOG_BTN_REMOVE_ALL_FILTERS: 'Zmazať filtre',
  EAS_TABLE_FILTER_DIALOG_BTN_SAVE: 'Uložiť',
  EAS_TABLE_FILTER_DIALOG_TITLE: 'Nastavenie filtrov',
  EAS_TABLE_NAMED_SETTINGS_BTN_SAVE: 'Uložiť',
  EAS_TABLE_NAMED_SETTINGS_DIALOG_TITLE_DETAIL: 'Uložiť nastavenia',
  EAS_TABLE_NAMED_SETTINGS_LABEL_FIELD_NAME: 'Názov',
  EAS_TABLE_NAMED_SETTINGS_LABEL_FIELD_SHARED: 'Zdieľané',
  EAS_TABLE_SAVED_MENU_BTN: 'Uložené nastavenia',
  EAS_TABLE_SAVED_MENU_CREATE: 'Uložiť ako...',
  EAS_TABLE_SAVED_MENU_UPDATE: 'Aktualizovať nastavenia',
  EAS_TABLE_SAVED_MENU_DELETE: 'Zmazať nastavenia',
  EAS_TABLE_SAVED_REMOVE_DIALOG_TEXT:
    'Naozaj chcete zmazať uložené nastavenia?',
  EAS_TABLE_SAVED_REMOVE_DIALOG_TITLE: 'Varovanie',
  EAS_TABLE_SEARCHBAR: 'Vyhľadať',
  EAS_TABLE_SEARCHBAR_BTN_ADVANCED: 'Pokročilé hľadanie',
  EAS_TABLE_SEARCHBAR_BTN_CLEAR: 'Zmazať text',
  EAS_TABLE_SEARCHBAR_BTN_SEARCH: 'Hľadať',
  EAS_TABLE_SELECT_ALL:
    'Výber položiek slúži ako vstup pre hromadné operácie (napr. export)',
  EAS_TABLE_TOOLBAR_BTN_COLUMN_DIALOG_OPEN: 'Nastavenie stĺpcov',
  EAS_TABLE_TOOLBAR_BTN_EXPORT_DIALOG_OPEN: 'Export',
  EAS_TABLE_TOOLBAR_BTN_FILTER_DIALOG_OPEN: 'Nastavenie filtrov',
  EAS_TABLE_TOOLBAR_BTN_NEW: 'Nový',
  EAS_TABLE_TOOLBAR_BTN_REFRESH: 'Obnoviť dáta',
  EAS_TABLE_TOOLBAR_BTN_RESET_SORTS: 'Obnovenie predvoleného radenia',
  EAS_TABLE_TOOLBAR_SELECTED:
    '{selectedCount, plural, one {1 vybraná položka} few {# vybrané položky} other {# vybraných položiek}}',
  EAS_TRANSLATIONS_COLUMN_LANGUAGE: 'Jazyk',
  EAS_TRANSLATIONS_FIELD_CONTENT: 'Súbor',
  EAS_TRANSLATIONS_FIELD_LANGUAGE: 'Jazyk',
  EAS_TRANSLATIONS_PANEL_TITLE: 'Preklad',
  EAS_TRANSLATIONS_TABLE_TITLE: 'Preklady',
  EAS_USER_SETTINGS_MSG_CLEAR_ERROR: 'Chyba mazania nastavenia: {detail}',
  EAS_USER_SETTINGS_MSG_LOAD_ERROR: 'Chyba načítania nastavenia: {detail}',
  EAS_USER_SETTINGS_MSG_SAVE_ERROR: 'Chyba uloženia nastavení: {detail}',
  EAS_VALIDATION_MSG_DOES_NOT_EXIST: 'Už existuje',
  EAS_VALIDATION_MSG_IS_THE_SAME: 'Nezhoduje sa',
  EAS_VALIDATION_MSG_MAX_LENGTH: 'Musí byť kratší ako {max} znakov',
  EAS_VALIDATION_MSG_MAX_SIZE: 'Počet záznamov musí byť najviac {max}',
  EAS_VALIDATION_MSG_MEETS_FORMAT: 'Chybný formát',
  EAS_VALIDATION_MSG_MEETS_RULES: 'Nespĺňa pravidlá',
  EAS_VALIDATION_MSG_MIN_SIZE: 'Počet záznamov musí byť najmenej {min}',
  EAS_VALIDATION_MSG_REQUIRED: 'Musí byť vyplnené',
  EAS__DICTIONARY_FIELD__ADD_BUTTON: 'Pridať',
  EAS__DICTIONARY_FIELD__CONFIRM_BUTTON: 'Potvrdiť',
  EAS__DICTIONARY_FIELD__DIALOG_TITLE: 'Výber',
  EAS__DICTIONARY_FIELD__EDIT_BUTTON: 'Upraviť',
  EAS__DICTIONARY_FIELD__ITEMS: 'Nájdené hodnoty',
  EAS__DICTIONARY_FIELD__RESET_BUTTON: 'Zrušiť filtre',
  EAS__DICTIONARY_FIELD__SEARCH_BUTTON: 'Hľadať',
  EAS__DICTIONARY_FIELD__SUBMIT_BUTTON: 'Použiť filter',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__COMMENT: 'Komentár',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__DESCRIPTION: 'Popis',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__NAME: 'Názov',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__UPDATED: 'Posledná úprava',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__UPDATED_BY: 'Autor úpravy',
  EAS__INTERNAL_SETTIINGS__DIALOG__FIELD_LABEL__VALUE: 'Hodnota',
  EAS__INTERNAL_SETTINGS__BTN_CANCEL: 'Zrušiť',
  EAS__INTERNAL_SETTINGS__BTN_EDIT: 'Upraviť',
  EAS__INTERNAL_SETTINGS__BTN_SAVE: 'Uložiť',
  EAS__INTERNAL_SETTINGS__COLUMN__NAME: 'Názov',
  EAS__INTERNAL_SETTINGS__COLUMN__UPDATED: 'Posledná úprava',
  EAS__INTERNAL_SETTINGS__COLUMN__UPDATED_BY: 'Autor úpravy',
  EAS__INTERNAL_SETTINGS__COLUMN__VALUE: 'Hodnota',
  EAS__INTERNAL_SETTTINGS__TITLE: 'Nastavenie aplikácie',
  EAS__TABLE_FIELD_FILTER__CONFIRM_BUTTON: 'Použiť filter',
  EAS__TABLE_FIELD_FILTER__DIALOG_TITLE: 'Nastavenie filtrov',
  EAS__TABLE_FIELD_FILTER__RESET_BUTTON: 'Zrušiť filtre',
  EAS__TREE_BUILDER: 'Hierarchia',
  EAS__TREE_BUILDER_DIALOG__NAME: 'Názov',
  EAS__TREE_BUILDER__ADD_NODE: 'Pridať uzol',
  EAS__TREE_BUILDER__ADD_NODE__TEXT: 'Vyplňte názov (nadradený uzol: {node})',
  EAS__TREE_BUILDER__ADD_NODE__TITLE: 'Pridať uzol',
  EAS__TREE_BUILDER__ADD_ROOT_NODE: 'Pridať priameho potomka',
  EAS__TREE_BUILDER__ADD_ROOT_NODE__TEXT: 'Vyplňte názov',
  EAS__TREE_BUILDER__ADD_ROOT_NODE__TITLE: 'Pridať uzol',
  EAS__TREE_BUILDER__EMPTY_SUB_TREE: 'Nie sú dostupné žiadne záznamy.',
  EAS__TREE_BUILDER__UPDATE_NODE: 'Upraviť uzol',
  EAS__TREE_BUILDER__UPDATE_NODE__TEXT: 'Upraviť názov',
  EAS__TREE_BUILDER__UPDATE_NODE__TITLE: 'Upraviť uzol',
  ESS_COMPONENTS_COLUMN_FILE: 'Súbor',
  ESS_COMPONENTS_COLUMN_SYNC_TIME: 'Čas synchronizácie',
  ESS_COMPONENTS_COLUMN_TYPE: 'Typ',
  ESS_COMPONENTS_FIELD_LABEL_FILE: 'Súbor',
  ESS_COMPONENTS_FIELD_LABEL_RECORD_SYNC_TIME: 'Čas synchronizácie',
  ESS_COMPONENTS_FIELD_LABEL_TYPE: 'Type',
  ESS_COMPONENTS_PANEL_TITLE: 'Komponenta',
  ESS_COMPONENTS_TABLE_TITLE: 'Komponenty',
  ESS_DISPATCHES_COLUMN_METHOD: 'Spôsob odoslania',
  ESS_DISPATCHES_COLUMN_STATE: 'Stav',
  ESS_DISPATCHES_FIELD_LABEL_METHOD: 'Type',
  ESS_DISPATCHES_FIELD_LABEL_STATE: 'Stav',
  ESS_DISPATCHES_PANEL_TITLE: 'Vypravenie',
  ESS_DISPATCHES_TABLE_TITLE: 'Vypravenie',
  ESS_DISPATCH_DELIVER_DIALOG_TEXT:
    'Skutočne chcete označiť vypravenie za doručené ?',
  ESS_DISPATCH_DELIVER_DIALOG_TITLE: 'Varovanie',
  ESS_DISPATCH_SEND_DIALOG_TEXT:
    'Skutočne chcete označiť vypravenie za odoslané ?',
  ESS_DISPATCH_SEND_DIALOG_TITLE: 'Varovanie',
  ESS_DISPATCH_TOOLBAR_BTN_DELIVER: 'Doručiť',
  ESS_DISPATCH_TOOLBAR_BTN_SEND: 'Odoslať',
  ESS_DISPATCH_TOOLBAR_TOOLTIP_DELIVER: 'Otvorte dialóg s ručným doručením',
  ESS_DISPATCH_TOOLBAR_TOOLTIP_SEND: 'Otvorte dialóg s ručným odoslaním',
  ESS_DOCUMENTS_COLUMN_BARCODE: 'Čiarový kód',
  ESS_DOCUMENTS_COLUMN_CLASSIFICATION_CODE: 'Spisový znak',
  ESS_DOCUMENTS_COLUMN_DESCRIPTION: 'Popis',
  ESS_DOCUMENTS_COLUMN_ESSID: 'ID Spisu',
  ESS_DOCUMENTS_COLUMN_NAME: 'Názov',
  ESS_DOCUMENTS_COLUMN_REFERENCE_NUMBER: 'Číslo rokovacie',
  ESS_DOCUMENTS_FIELD_COMPONENTS_PANEL_TITLE: 'Súbory',
  ESS_DOCUMENTS_FIELD_LABEL_DESCRIPTION: 'Popis',
  ESS_DOCUMENTS_FIELD_LABEL_NAME: 'Názov',
  ESS_DOCUMENTS_FIELD_LABEL_RECORD_BARCODE: 'Čiarový kód',
  ESS_DOCUMENTS_FIELD_LABEL_RECORD_CLASSIFICATION_CODE: 'Spisový znak',
  ESS_DOCUMENTS_FIELD_LABEL_RECORD_REFERENCE_NUMBER: 'Číslo rokovacie',
  ESS_DOCUMENTS_FIELD_RECORD_PANEL_TITLE: 'Spis',
  ESS_DOCUMENTS_PANEL_TITLE: 'Dokument',
  ESS_DOCUMENTS_RECORD_IDENTIFIER_FIELD_LABEL_ID: 'Id',
  ESS_DOCUMENTS_RECORD_IDENTIFIER_FIELD_LABEL_SOURCE: 'Zdroj',
  ESS_DOCUMENTS_TABLE_TITLE: 'Dokumenty',
  ESS_IDENTIFIER_FIELD_LABEL_ID: 'Id',
  ESS_IDENTIFIER_FIELD_LABEL_SOURCE: 'Zdroj',
  ESS_IDENTIFIER_PANEL_TITLE: 'Identifikátor',
  ESS_INCOMING_BATCHES_COLUMN_CREATION_DATE: 'Dátum vzniku',
  ESS_INCOMING_BATCHES_COLUMN_ORDER: 'Poradie',
  ESS_INCOMING_BATCHES_COLUMN_PROCESSED_DATE: 'Dátum spracovania',
  ESS_INCOMING_BATCHES_COLUMN_STATE: 'Stav',
  ESS_INCOMING_BATCHES_DETAIL_TITLE: 'Prichádzajúca dávka',
  ESS_INCOMING_BATCHES_EXTERNALIZE_DIALOG_TEXT:
    'Skutočne chcete všetky udalosti z dávky externalizovať?',
  ESS_INCOMING_BATCHES_EXTERNALIZE_DIALOG_TITLE: 'Varovanie',
  ESS_INCOMING_BATCHES_EXTERNALIZE_PROCESSED_DIALOG_TEXT:
    'Chcete vynútiť externalizáciu všetkých spracovaných dávok ?',
  ESS_INCOMING_BATCHES_EXTERNALIZE_PROCESSED_DIALOG_TITLE: 'Varovanie',
  ESS_INCOMING_BATCHES_FIELD_LABEL_CREATION_DATE: 'Dátum vzniku',
  ESS_INCOMING_BATCHES_FIELD_LABEL_EVENT_CONTENT: 'Obsah',
  ESS_INCOMING_BATCHES_FIELD_LABEL_EVENT_CONTENT_FILE: 'Súbor s obsahom',
  ESS_INCOMING_BATCHES_FIELD_LABEL_EVENT_ERROR_MESSAGE: 'Chyba',
  ESS_INCOMING_BATCHES_FIELD_LABEL_EVENT_RESULT: 'Výsledok',
  ESS_INCOMING_BATCHES_FIELD_LABEL_MESSAGE_CODE: 'Kód',
  ESS_INCOMING_BATCHES_FIELD_LABEL_MESSAGE_DESCRIPTION: 'Chyba',
  ESS_INCOMING_BATCHES_FIELD_LABEL_ORDER: 'Poradie',
  ESS_INCOMING_BATCHES_FIELD_LABEL_PROCESSED_DATE: 'Dátum spracovania',
  ESS_INCOMING_BATCHES_FIELD_LABEL_STATE: 'Stav',
  ESS_INCOMING_BATCHES_INTERNALIZE_DIALOG_TEXT:
    'Skutočne chcete všetky udalosti z dávky internalizovať?',
  ESS_INCOMING_BATCHES_INTERNALIZE_DIALOG_TITLE: 'Varovanie',
  ESS_INCOMING_BATCHES_PANEL_TITLE: 'Prichádzajúca dávka',
  ESS_INCOMING_BATCHES_PROCESS_DIALOG_TEXT:
    'Chcete vynútiť spracovanie všetkých dávok zo spisovej služby?',
  ESS_INCOMING_BATCHES_PROCESS_DIALOG_TITLE: 'Varovanie',
  ESS_INCOMING_BATCHES_TABLE_TITLE: 'Prichádzajúce dávky',
  ESS_INCOMING_BATCHES_TOOLBAR_BTN_EXTERNALIZE: 'Externalizovať',
  ESS_INCOMING_BATCHES_TOOLBAR_BTN_EXTERNALIZE_PROCESSED: 'E',
  ESS_INCOMING_BATCHES_TOOLBAR_BTN_INTERNALIZE: 'Internalizovať',
  ESS_INCOMING_BATCHES_TOOLBAR_BTN_PROCESS: 'Sync',
  ESS_INCOMING_BATCHES_TOOLBAR_TOOLTIP_EXTERNALIZE:
    'Otvorí dialóg s potvrdením',
  ESS_INCOMING_BATCHES_TOOLBAR_TOOLTIP_EXTERNALIZE_PROCESSED:
    'Externalizovať spracované',
  ESS_INCOMING_BATCHES_TOOLBAR_TOOLTIP_INTERNALIZE:
    'Otvorí dialóg s potvrdením',
  ESS_INCOMING_BATCHES_TOOLBAR_TOOLTIP_PROCESS: 'Spracovať',
  ESS_OUTGOING_BATCHES_COLUMN_CREATION_DATE: 'Dátum vzniku',
  ESS_OUTGOING_BATCHES_COLUMN_ORDER: 'Poradie',
  ESS_OUTGOING_BATCHES_COLUMN_PROCESSED_DATE: 'Dátum spracovania',
  ESS_OUTGOING_BATCHES_COLUMN_STATE: 'Stav',
  ESS_OUTGOING_BATCHES_DETAIL_TITLE: 'Odchádzajúca dávka',
  ESS_OUTGOING_BATCHES_EXTERNALIZE_DIALOG_TEXT:
    'Skutočne chcete všetky udalosti z dávky externalizovať?',
  ESS_OUTGOING_BATCHES_EXTERNALIZE_DIALOG_TITLE: 'Varovanie',
  ESS_OUTGOING_BATCHES_EXTERNALIZE_PROCESSED_DIALOG_TEXT:
    'Chcete vynútiť externalizáciu všetkých spracovaných dávok ?',
  ESS_OUTGOING_BATCHES_EXTERNALIZE_PROCESSED_DIALOG_TITLE: 'Varovanie',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_CREATION_DATE: 'Dátum vzniku',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_EVENT_CONTENT: 'Obsah',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_EVENT_CONTENT_FILE: 'Súbor s obsahom',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_EVENT_ERROR_MESSAGE: 'Chyba',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_EVENT_RESULT: 'Výsledok',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_EXTERNALIZED: 'Externalizované',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_MESSAGE_CODE: 'Kód',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_MESSAGE_DESCRIPTION: 'Chyba',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_ORDER: 'Poradie',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_PROCESSED_DATE: 'Dátum spracovania',
  ESS_OUTGOING_BATCHES_FIELD_LABEL_STATE: 'Stav',
  ESS_OUTGOING_BATCHES_INTERNALIZE_DIALOG_TEXT:
    'Skutočne chcete všetky udalosti z dávky internalizovať?',
  ESS_OUTGOING_BATCHES_INTERNALIZE_DIALOG_TITLE: 'Varovanie',
  ESS_OUTGOING_BATCHES_PANEL_TITLE: 'Odchádzajúca dávka',
  ESS_OUTGOING_BATCHES_SEND_DIALOG_TEXT:
    'Chcete vynútiť odoslanie všetkých dávok do spisovej služby ?',
  ESS_OUTGOING_BATCHES_SEND_DIALOG_TITLE: 'Varovanie',
  ESS_OUTGOING_BATCHES_SKIP_FAILED_DIALOG_TEXT:
    'Chcete opraviť chybnú dávku vyradením chybnej udalosti?',
  ESS_OUTGOING_BATCHES_SKIP_FAILED_DIALOG_TITLE: 'Varovanie',
  ESS_OUTGOING_BATCHES_TABLE_TITLE: 'Odchádzajúce dávky',
  ESS_OUTGOING_BATCHES_TOOLBAR_BTN_EXTERNALIZE: 'Externalizovať',
  ESS_OUTGOING_BATCHES_TOOLBAR_BTN_EXTERNALIZE_PROCESSED: 'E',
  ESS_OUTGOING_BATCHES_TOOLBAR_BTN_INTERNALIZE: 'Internalizovať',
  ESS_OUTGOING_BATCHES_TOOLBAR_BTN_SKIP_FAILED: 'V',
  ESS_OUTGOING_BATCHES_TOOLBAR_BTN_TRY_FAILED: 'Z',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_EXTERNALIZE:
    'Otvorí dialóg s potvrdením',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_EXTERNALIZE_PROCESSED:
    'Externalizovať spracované',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_INTERNALIZE:
    'Otvorí dialóg s potvrdením',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_SEND: 'Odošle čakajúce dávky',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_SKIP_FAILED:
    'Zaradí znova chybnú dávku s vyradenou chybnou udalosťou',
  ESS_OUTGOING_BATCHES_TOOLBAR_TOOLTIP_TRY_FAILED:
    'Zaradí znova chybnú dávku bez zmeny',
  ESS_OUTGOING_BATCHES_TRY_FAILED_DIALOG_TEXT:
    'Chcete opraviť chybnú dávku bez zmeny?',
  ESS_OUTGOING_BATCHES_TRY_FAILED_DIALOG_TITLE: 'Varovanie',
  ESS_RECORDS_COLUMN_BARCODE: 'Čiarový kód',
  ESS_RECORDS_COLUMN_CLASSIFICATION_CODE: 'Spisový znak',
  ESS_RECORDS_COLUMN_DESCRIPTION: 'Popis',
  ESS_RECORDS_COLUMN_NAME: 'Názov',
  ESS_RECORDS_COLUMN_RECORD_SYMBOL: 'Spisová značka',
  ESS_RECORDS_FIELD_LABEL_DESCRIPTION: 'Popis',
  ESS_RECORDS_FIELD_LABEL_NAME: 'Názov',
  ESS_RECORDS_FIELD_LABEL_RECORD_BARCODE: 'Čiarový kód',
  ESS_RECORDS_FIELD_LABEL_RECORD_CLASSIFICATION_CODE: 'Spisový znak',
  ESS_RECORDS_FIELD_LABEL_RECORD_SYMBOL: 'Spisová značka',
  ESS_RECORDS_PANEL_TITLE: 'Skartačný režim',
  ESS_RECORDS_TABLE_TITLE: 'Spisy',
  ESS_SHREDDING_MODES_COLUMN_PERIOD: 'Skartačná lehota',
  ESS_SHREDDING_MODES_COLUMN_SYMBOL: 'Skartačný znak',
  ESS_SHREDDING_MODES_FIELD_LABEL_PERIOD: 'Skartačná lehota',
  ESS_SHREDDING_MODES_FIELD_LABEL_SYMBOL: 'Skartačný znak',
  ESS_SHREDDING_MODES_TABLE_TITLE: 'Skartačné režimy',
  ESS_SHREDING_MODE_FIELD_LABEL_PERIOD: 'Skartačná lehota',
  ESS_SHREDING_MODE_FIELD_LABEL_SYMBOL: 'Skartačný znak',
  ESS_SHREDING_MODE_PANEL_TITLE: 'Skartačný režim',
  ES__AUTOCOMPLETE__DIALOG_CONFIRM__TEXT:
    'Naozaj chcete vymazať všetky položky?',
  ES__AUTOCOMPLETE__DIALOG_CONFIRM__TITLE: 'Varovanie',
  ES__DETAIL__ACTIONBAR__CHECK: 'Kontrola',
  ES__DETAIL__ACTIONBAR__CLOSE: 'Zrušiť',
  ES__DETAIL__ACTIONBAR__EDIT: 'Upraviť',
  ES__DETAIL__ACTIONBAR__EXPORT: 'Exportovať',
  ES__DETAIL__ACTIONBAR__LOCK:
    'Záznam je uzamknutý, nie je možné ho ďalej editovať.',
  ES__DETAIL__ACTIONBAR__NEW: 'Nový',
  ES__DETAIL__ACTIONBAR__REFRESH: 'Obnoviť',
  ES__DETAIL__ACTIONBAR__REMOVE: 'Zmazať',
  ES__DETAIL__ACTIONBAR__SAVE: 'Uložiť',
  ES__DETAIL__ACTIONBAR__SHARE: 'Zdieľať',
  ES__DETAIL__DIALOG_CONFIRM__TEXT: 'Naozaj chcete zmazať záznam?',
  ES__DETAIL__DIALOG_CONFIRM__TITLE: 'Varovanie',
  ES__DICTIONARY__ACTIONBAR__INVALIDATE: 'Zneplatniť',
  ES__DICTIONARY__ACTIONBAR__REVALIDATE: 'Obnoviť platnosť',
  ES__DICTIONARY__DIALOG_INVALIDATE__TEXT: 'Naozaj chcete zneplatniť záznam?',
  ES__DICTIONARY__DIALOG_INVALIDATE__TITLE: 'Varovanie',
  ES__DICTIONARY__DIALOG_REVALIDATE__TEXT:
    'Naozaj chcete obnoviť platnosť záznamu?',
  ES__DICTIONARY__DIALOG_REVALIDATE__TITLE: 'Varovanie',
  ES__EXPORT_REQUESTS__DETAIL__TITLE: 'Fronta exportu',
  ES__EXPORT_TEMPLATES__DETAIL__TITLE: 'Šablóna exportu',
  ES__NOTES_TOOLBAR__ADD_NOTE__BUTTON: 'Pridať',
  ES__NOTIFICATION__ACTIONMENU__DELETE: 'Zmazať notifikáciu',
  ES__NOTIFICATION__ACTIONMENU__DELETE__TEXT:
    'Skutočne chcete zmazať notifikáciu?',
  ES__NOTIFICATION__ACTIONMENU__DELETE__TITLE: 'Varovanie',
  ES__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE:
    'Nechcem dostávať podobné notifikácie',
  ES__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE__TEXT:
    'Skutočne nechcete byť upozorňovaní o podobných udalostiach?',
  ES__NOTIFICATION__ACTIONMENU__UNSUBSCRIBE__TITLE: 'Varovanie',
  ES__NOTIFICATION__ACTIONMENU__MARK_AS_READ: 'Označiť ako prečítané',
  ES__NOTIFICATION__ACTIONMENU__MARK_AS_UNREAD: 'Označiť ako neprečítané',
  KS_O_EXPORT_REQUESTS_FIELD_RESULT_PANEL_TITLE: 'Výsledok',
  ES__REDIRECT__OPEN_IN_CURRENT: 'Otvorí sa v aktuálnej karte',
  ES__REDIRECT__OPEN_IN_NEW: 'Otvorí sa v novej karte',
  ZSD_GANTT_DIMENSIONS_LABEL: 'Krok zobrazenia',
  ZSD_GANTT_DRAG_STEP_LABEL: 'Krok plánovania',
  ZSD__APP_TITLE: 'ZSD - Systém na riadenie výkonu v teréne',
  ZSD__LOGIN__BUTTON: 'Prihlásiť',
  ZSD__LOGIN__PASSWORD: 'Heslo',
  ZSD__LOGIN__TITLE: 'Prihlásenie',
  ZSD__LOGIN__USERNAME: 'Užívateľské meno',
  ZSD__MENU__CALENDAR: 'Kalendár',
  ZSD__MENU__PROFILE: 'Profil',
  ZSD__MENU__ROLES: 'Správa oprávnení',
  ZSD__MENU__USERS: 'Správa používateľov',
  ZSD__MENU__USER_GROUP: 'Používatelia',
  ZSD__MENU__REGIONS: 'Org. štruktúra',
  ZSD__MENU__SECTIONS: 'Úseky',
  ZSD__MENU__TEAMS: 'Tímy',
  ZSD__MENU__DEPARTMENTS: 'Pracoviská',
  ZSM__MENU__WORK_ORDERS: 'Pracovné príkazy',
  ZSD__MENU__MOBILE_UNITS: 'Mobilné jednotky',
  ZSD__USERS__TABLE__TITLE: 'Používatelia',
  ZSD__USERS__DETAIL__TITLE: 'Používateľ',
  ZSD__USERS__TABLE__PANEL__GENERAL: 'Všeobecné',
  ZSD__USERS__TABLE__COLUMN__HR_ID: 'Osobné číslo',
  ZSD__USERS__TABLE__COLUMN__FIRSTNAME: 'Meno',
  ZSD__USERS__TABLE__COLUMN__LASTNAME: 'Priezvisko',
  ZSD__USERS__TABLE__COLUMN__PHONE_NUMBER: 'Telefónne číslo',
  ZSD__USERS__TABLE__COLUMN__USERNAME: 'KID',
  ZSD__USERS__TABLE__COLUMN__DEPARTMENT: 'Pracovisko',
  ZSD__USERS__TABLE__COLUMN__ADDITIONAL_DEPARTMENTS: 'Dalšie pracoviská',
  ZSD__USERS__TABLE__COLUMN__TYPE: 'Typ používateľa',
  ZSD__USERS__TABLE__COLUMN__ROLE: 'Oprávnenia',
  ZSD__USERS__TABLE__COLUMN__IS_EXTERNAL: 'Externí',
  ZSD__USERS__TABLE__COLUMN__PERSONAL_ADDRESS: 'Adresa bydliska',
  ZSD__USERS__TABLE__COLUMN__BUSINESS_ADDRESS: 'Služebná adresa',
  ZSD__USERS__DETAIL__FIELD_LABEL__HR_ID: 'Osobné číslo',
  ZSD__USERS__DETAIL__FIELD_LABEL__FIRSTNAME: 'Meno',
  ZSD__USERS__DETAIL__FIELD_LABEL__LASTNAME: 'Priezvisko',
  ZSD__USERS__DETAIL__FIELD_LABEL__PHONE_NUMBER: 'Telefónne číslo',
  ZSD__USERS__DETAIL__FIELD_LABEL__USERNAME: 'Prihlasovací KID',
  ZSD__USERS__DETAIL__FIELD_LABEL__USER_TYPE: 'Typ používateľa',
  ZSD__USERS__DETAIL_FIELD_LABEL__DEPARTMENT: 'Pracovisko',
  ZSD__USERS__DETAIL_FIELD_LABEL__ADDITIONAL_DEPARTMENTS: 'Dalšie pracoviská',
  ZSD__USERS__DETAIL__FIELD_LABEL__ROLE: 'Skupiny oprávnení',
  ZSD__USERS__DETAIL__FIELD_LABEL__IS_EXTERNAL: 'Externá osoba',
  ZSD__USERS__DETAIL_FIELD_LABEL__TEAM: 'Tím',
  ZSD__USERS__DETAIL_FIELD_LABEL__SECTION: 'Úsek',
  ZSD__USERS__DETAIL__ERROR__DEPARTMENT_AND_TYPE:
    'Ak je zadané pracovisko, nemôžete nastaviť iný typ používateľa ako technik.',
  ZSD__USERS__DETAIL__ERROR__SECTION_AND_TYPE:
    'Ak je zadaný úsek, nemôžete nastaviť iný typ používateľa ako manažment.',
  ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__PLAN_WORK: 'Naplánovať prácu',
  ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__MONTH_PLAN: 'Mesačný plán',
  ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__CURRENT_LOCATION:
    'Zobraziť aktuálnu polohu',
  ZSD__ADDRESS__TABLE__COLUMN__CITY: 'Mesto',
  ZSD__ADDRESS__TABLE__COLUMN__STREET: 'Ulica',
  ZSD__ADDRESS__TABLE__COLUMN__HOUSE_NUMBER: 'Číslo domu',
  ZSD__ADDRESS__TABLE__COLUMN__POST_CODE: 'PSČ',
  ZSD__ROLES__TABLE__TITLE: 'Oprávnenia',
  ZSD__ROLES__DETAIL__TITLE: 'Správa oprávnení',
  ZSD__ROLES__DETAIL__PANEL__GENERAL: 'Všeobecné',
  ZSD__ROLES__TABLE__COLUMN__NAME: 'Název',
  ZSD__SECTIONS__TABLE__TITLE: 'Zoznam úsekov',
  ZSD__SECTIONS__DETAIL__TITLE: 'Úsek',
  ZSD__SECTIONS__DETAIL__PANEL__GENERAL: 'Všeobecné',
  ZSD__SECTIONS__DETAIL__FIELD_LABEL__TEAMS: 'Tímy',
  ZSD__TEAMS__TABLE__TITLE: 'Tímy',
  ZSD__TEAMS__DETAIL__TITLE: 'Tím',
  ZSD__TEAMS__DETAIL__PANEL__GENERAL: 'Všeobecné',
  ZSD__TEAMS__TABLE__COLUMN__SECTION: 'Úsek',
  ZSD__TEAMS__DETAIL__FIELD_LABEL__SECTION: 'Úsek',
  ZSD__SECTIONS__DETAIL__FIELD_LABEL__DEPARTMENTS: 'Pracoviská',
  ZSD__DEPARTMENTS__TABLE__TITLE: 'Pracoviská',
  ZSD__DEPARTMENTS__DETAIL__TITLE: 'Pracovisko',
  ZSD__DEPARTMENTS__TABLE__COLUMN__TEAM: 'Tím',
  ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__TEAM: 'Tím',
  ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__CODE: 'Kód',
  ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__USERS: 'Technici',
  ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__MOBILE_UNITS: 'Mobilné jednotky',
  ZSD__COLLECTION_COUNT: 'Čítanie / Celkový počet záznamov',
  ZSD__COLLECTIONS__FIELD_LABEL__NAME: 'Názov',
  ZSD__COLLECTIONS__ADD_ERROR: 'Chyba vytvorenia záznamu',
  ZSD__COLLECTIONS__DEFAULT_ERROR: 'Chyba volania akcie',
  ZSD__COLLECTIONS__ADD_LABEL: 'Nový',
  ZSD__COLLECTIONS__ADD_TITLE: 'Vytvorenie',
  ZSD__COLLECTIONS__ADD_TEXT: 'Vyplňte formulár',
  ZSD__COLLECTIONS__ASSIGN_LABEL: 'Pridať',
  ZSD__COLLECTIONS__ASSIGN_TITLE: 'Priradenie',
  ZSD__COLLECTIONS__ASSIGN_TEXT: 'Vyberte položku',
  ZSD__COLLECTIONS__DIALOG__ROLE__ASSIGN_USERS: 'Priradení používatelia',
  ZSD__WORK_ORDERS__TABLE__TITLE: 'Pracovné príkazy',
  ZSD__WORK_ORDERS__DETAIL__TITLE: 'Pracovný príkaz',
  ZSD__WORK_ORDERS__DETAIL__PANEL__GENERAL: 'Všeobecné',
  ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CONTRACT_NUMBER: 'Číslo zákazky',
  ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__OPERATION_NUMBER: 'Číslo operácie',
  ZSD__WORK_ORDERS__TABLE__COLUMN__CONTRACT_NUMBER: 'Číslo zákazky',
  ZSD__WORK_ORDERS__TABLE__COLUMN__OPERATION_NUMBER: 'Číslo operácie',
  ZSD__WORK_ORDERS__DETAIL__ACTIONBAR__BUTTON__DEACTIVATE: 'Zneaktívnit',
  ZSD__WORK_ORDERS__DETAIL__ACTIONBAR__BUTTON__ACTIVATE: 'Aktivovať',
  ZSD__WORK_ORDERS__DETAIL__ACTIONBAR__BUTTON__PLAN_WORK: 'Naplánovat práci',
  ZSD__MOBILE_UNITS__TABLE__TITLE: 'Mobilné jednotky',
  ZSD__MOBILE_UNITS__DETAIL__TITLE: 'Mobilná jednotka',
  ZSD__MOBILE_UNITS__DETAIL__FIELD_LABEL__DEPARTMENT: 'Pracovisko',
  ZSD__MOBILE_UNITS__TABLE__COLUMN__NAME: 'Názov',
  ZSD__MOBILE_UNITS__TABLE__COLUMN__DEPARTMENT: 'Pracovisko',
  ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__NAME: 'Názov',
  ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__DEPARTMENT: 'Pracovisko',
  ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__PLAN_WORK: 'Naplánovať prácu',
  ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__MONTH_PLAN: 'Mesačný plán',
  ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__PLAN_EMERGENCY:
    'Naplánovať pohotovosť',
  ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CURRENT_LOCATION:
    'Zobraziť aktuálnu polohu',
  ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__DEACTIVATE: 'Zneaktívnit',
  ZSD__FIELD_LABEL__MANAGEMENT: 'Manažment',
  ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__MU_NAME:
    'Názov mobilnej jednotky',
  ZSD__FIELD_LABEL__MOBILE_UNIT_ASSIGNMENTS: 'Priradenie technikov k MJ',
  EAS_COLOR_PICKER_SET: 'Nastaviť',
  EAS_COLOR_PICKER_WRONG_FORMAT: 'Nesprávny formát',
  EAS_COLOR_PICKER_NOT_AN_HEX_VALUE: 'Nie je hexadecimálna hodnota',
};
