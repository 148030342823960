import cn from 'clsx';
import L from 'leaflet';
import React, { CSSProperties, useContext, useRef } from 'react';
import { FC, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useMap } from 'react-leaflet';
import * as ReactLeaflet from 'react-leaflet';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TO_MAP_CONSTS } from '../container/constants';
import { MapTechnicalObjectsContext } from '../context/map-technical-objects-context';
import { SVGDefinition } from './technical-objects/types';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    filter: 'drop-shadow(0px 0px 8px rgba(0,0,200,0.9))',
    opacity: '1!important',
    fontSize: `${TO_MAP_CONSTS.labelsFontSizeSelected}px!important`,
  },
  label: {
    fontSize: TO_MAP_CONSTS.labelsFontSize,
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  labelTop: { top: -50 },
  labelBottom: { bottom: -50 },
}));

type CreateSVGIconArgs = {
  width: number;
  height: number;
  jsx: JSX.Element;
  svgWrapperStyle: CSSProperties;
  label?: React.ReactNode;
  labelPosition?: 'top' | 'bottom';
  svgClasses: ReturnType<typeof useStyles>;
  centerAnchorFactors: [number, number];
  selected?: boolean;
};

const createSVGIcon = ({
  width,
  height,
  jsx,
  svgWrapperStyle,
  label,
  labelPosition = 'bottom',
  svgClasses,
  centerAnchorFactors,
  selected,
}: CreateSVGIconArgs) => {
  return L.divIcon({
    className: 'custom-div-icon',
    html: renderToString(
      <div style={{ overflow: 'visible' }}>
        <div
          className={cn(svgClasses.label, {
            [svgClasses.labelTop]: labelPosition === 'top',
            [svgClasses.labelBottom]: labelPosition === 'bottom',
            [svgClasses.selected]: selected,
          })}
          style={svgWrapperStyle}
        >
          {label}
        </div>

        <div
          className={cn(svgClasses.wrapper, {
            [svgClasses.selected]: selected,
          })}
          style={{
            ...svgWrapperStyle,
            ...(selected ? { strokeWidth: 6 } : {}),
          }}
        >
          {jsx}
        </div>
      </div>
    ),
    iconSize: [width, height],
    iconAnchor: [
      width / centerAnchorFactors[0],
      height / centerAnchorFactors[1],
    ],
    shadowSize: [0, 0],
  });
};
type CustomSVGMarkerProps = {
  position: [number, number];
  onClick?: (e: L.LeafletMouseEvent) => void;
  svg: SVGDefinition;
  selected?: boolean;
};

export const SVGMarker: FC<CustomSVGMarkerProps> = ({
  position,
  onClick,
  svg,
  selected,
}) => {
  const map = useMap();
  const { showLabels } = useContext(MapTechnicalObjectsContext);
  const svgClasses = useStyles();
  const ref = useRef<L.Marker>(null);
  useEffect(() => {
    // update marker size on zoom fn
    const updateMarkerSize = () => {
      const zoom = map.getZoom();
      const scaleFactor = Math.pow(
        TO_MAP_CONSTS.scaleSvgFactorOnZoom,
        zoom - 19
      );
      const Icon = createSVGIcon({
        width: svg.width * scaleFactor * svg.scale,
        height: svg.height * scaleFactor * svg.scale,
        jsx: svg.jsx,
        svgWrapperStyle: svg.style,
        label:
          zoom > TO_MAP_CONSTS.labelsMinZoom && showLabels ? svg.label : '',
        labelPosition: svg.labelPosition,
        svgClasses,
        centerAnchorFactors: svg.centerAnchorFactors ?? [2, 2],
        selected,
      });
      ref.current?.setIcon(Icon);
    };

    map.on('zoom', updateMarkerSize);
    updateMarkerSize();

    return () => {
      map.off('zoom', updateMarkerSize);
    };
  }, [map, showLabels, svg, svgClasses, selected]);

  return (
    <ReactLeaflet.Marker
      eventHandlers={{
        click: onClick,
      }}
      position={position}
      icon={createSVGIcon({
        width: svg.width * svg.scale,
        height: svg.height * svg.scale,
        jsx: svg.jsx,
        svgWrapperStyle: svg.style,
        label: showLabels ? svg.label : '',
        labelPosition: svg.labelPosition,
        svgClasses,
        centerAnchorFactors: svg.centerAnchorFactors ?? [2, 2],
        selected,
      })}
      ref={ref}
    />
  );
};
