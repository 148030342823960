import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  columnUserLabelMapper,
  dangerBooleanColumnMapper,
  durationColumnMapper,
} from 'src/utils/label-mapper';
import {
  ApiFilterOperation,
  FieldFilter,
  FilterComponentProps,
  Select,
  TableCells,
  TableColumn,
  TableFilterCells,
  TimeField,
  useCompareOperations,
  useFilterOperation,
  useFilterStyles,
} from '@eas/common-web';
import { useCertifications } from '@modules/certification/certification-api';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { useMobileUnits } from '@modules/mobile-unit/mobile-unit-api';
import { useSectionAutocomplete } from '@modules/section/section-api';
import { useTeamAutocomplete } from '@modules/team/team-api';
import { useUsersAutocomplete } from '@modules/user/user-api';
import { useWorkOrderStates } from '@modules/work-order-state/work-order-state-api';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';
import { WorkOrder } from '@models';
import { universalUserLabelMapper } from '@utils';
import { useWorkOrders } from './work-order-api';
import { useWorkOrderStateColumn } from './work-order-utils';

function DurationFilterComponent({
  disabled,
  value,
  onChange,
  state,
  onChangeState,
}: FilterComponentProps) {
  const classes = useFilterStyles();
  const operations = useCompareOperations();
  const { handleChangeOperation } = useFilterOperation(state, onChangeState);

  // seconds to HH:MM
  const hours = Math.floor(value / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((value % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (value % 60).toString().padStart(2, '0');
  const filterValue = `${hours}:${minutes}:${seconds}`;

  const handleChange = (newValue: string | null) => {
    if (newValue === null) {
      onChange(null);
    } else {
      const [hours, minutes, seconds] = newValue.split(':');

      onChange(
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds)
      );
    }
  };

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <div className={classes.selectWrapper}>
        <Select
          source={operations}
          value={state.operation}
          onChange={handleChangeOperation}
          valueIsId={true}
          clearable={false}
          disabled={disabled}
        />
      </div>
      <div className={classes.textWrapper}>
        <TimeField
          value={filterValue}
          onChange={handleChange}
          disabled={disabled}
          showSeconds={true}
        />
      </div>
    </div>
  );
}

export function useColumns(): TableColumn<WorkOrder>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'workOrderId',
      sortkey: 'workOrderId',
      filterkey: 'workOrderId',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CONTRACT_NUMBER',
        defaultMessage: 'Číslo zákazky',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 0,
      filterOrder: 0,
    },
    {
      datakey: 'operationNumber',
      sortkey: 'operationNumber',
      filterkey: 'operationNumber',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__OPERATION_NUMBER',
        defaultMessage: 'Číslo operácie',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 0,
      filterOrder: 1,
    },
    {
      datakey: 'received',
      sortkey: 'received',
      filterkey: 'received',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__RECEIVED',
        defaultMessage: 'PP prijatý na MK',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 0,
      filterOrder: 2,
      visible: false,
    },
    {
      datakey: 'priority',
      sortkey: 'priority',
      filterkey: 'priority',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__PRIORITY',
        defaultMessage: 'Priorita',
      }),
      width: 80,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 0,
      filterOrder: 3,
    },
    {
      datakey: 'currentState',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 100,
      CellComponent: function TextCell({ rowValue }) {
        return useWorkOrderStateColumn(rowValue.currentState);
      },
      sortable: true,
      sortkey: 'currentState.name',
      filterable: true,
      filterkey: 'currentState.id',
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useWorkOrderStates,
          (state) => state.label ?? ''
        ),
      filterGroup: 0,
      filterOrder: 3,
      valueMapper: TableCells.dictionaryColumnMapper,
    },
    {
      datakey: 'workOrderType',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__WORK_ORDER_Type',
        defaultMessage: 'Druh PP',
      }),
      width: 100,
      sortable: true,
      sortkey: 'workOrderType.name',
      filterable: true,
      filterkey: 'workOrderType.id',
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useWorkOrderTypes
        ),
      filterGroup: 0,
      filterOrder: 4,
      valueMapper: TableCells.dictionaryColumnMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'department.name',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__DEPARTMENT',
        defaultMessage: 'Pracovisko',
      }),
      width: 150,
      sortable: true,
      sortkey: 'department.name',
      filterable: true,
      filterkey: 'department.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useDepartmentAutocomplete
        ),
      filterGroup: 1,
      filterOrder: 0,
      // valueMapper: TableCells.dictionaryColumnMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'department.team.name',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__TEAM',
        defaultMessage: 'Tím',
      }),
      width: 150,
      sortable: true,
      sortkey: 'department.team.name',
      filterable: true,
      filterkey: 'department.team.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useTeamAutocomplete
        ),
      filterGroup: 1,
      filterOrder: 1,
      // valueMapper: TableCells.dictionaryColumnMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'department.team.section.name',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__SECTION',
        defaultMessage: 'Úsek',
      }),
      width: 150,
      sortable: true,
      sortkey: 'department.team.section.name',
      filterable: true,
      filterkey: 'department.team.section.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useSectionAutocomplete
        ),
      filterGroup: 1,
      filterOrder: 2,
      // valueMapper: TableCells.dictionaryColumnMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'mobileUnit',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__MOBILE_UNIT',
        defaultMessage: 'Priradený k mobilnej jednotke',
      }),
      width: 150,
      sortable: true,
      sortkey: 'mobileUnit.name',
      filterable: true,
      filterkey: 'mobileUnit.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useMobileUnits,
          (mu) => mu.label ?? ''
        ),
      filterGroup: 1,
      filterOrder: 3,
      valueMapper: TableCells.dictionaryColumnMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'user',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__USER',
        defaultMessage: 'Priradený k technikovi',
      }),
      width: 150,
      sortable: true,
      sortkey: 'user.firstName',
      filterable: true,
      filterkey: 'user.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useUsersAutocomplete,
          universalUserLabelMapper
        ),
      filterGroup: 1,
      filterOrder: 4,
      valueMapper: columnUserLabelMapper,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'locked',
      sortkey: 'locked',
      filterkey: 'locked',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__LOCKED',
        defaultMessage: 'Uzamknutý',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 5,
      visible: false,
    },
    {
      datakey: 'fixed',
      sortkey: 'fixed',
      filterkey: 'fixed',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__FIXED',
        defaultMessage: 'Fixný',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 6,
      visible: false,
    },
    {
      datakey: 'indivisible',
      sortkey: 'indivisible',
      filterkey: 'indivisible',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__INDIVISIBLE',
        defaultMessage: 'Neprerušitelná práca',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 7,
      visible: false,
    },
    {
      datakey: 'customerAgreed',
      sortkey: 'customerAgreed',
      filterkey: 'customerAgreed',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CUSTOMER_AGREED',
        defaultMessage: 'Dohodnuté so zákazníkom',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 8,
      visible: false,
    },
    {
      datakey: 'maintenanceFailure',
      sortkey: 'maintenanceFailure',
      filterkey: 'maintenanceFailure',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__MAINTENANCE_FAILURE',
        defaultMessage: 'Porucha v údržbe (PVU)',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 9,
      visible: false,
    },
    {
      datakey: 'inDanger',
      sortkey: 'inDanger',
      filterkey: 'inDanger',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__IN_DANGER',
        defaultMessage: 'Nebezpečenstvo',
      }),
      width: 80,
      CellComponent: function TextCell({ value, ...props }) {
        if (value === 1) {
          return <TableCells.TextCell value="Nie" {...props} />;
        } else if (value === 2) {
          return (
            <div
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '0 8px',
              }}
            >
              <TableCells.TextCell value="Áno" {...props} />
            </div>
          );
        }
        return <TableCells.TextCell value="" {...props} />;
      },
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 1,
      filterOrder: 10,
      valueMapper: dangerBooleanColumnMapper,
      visible: false,
    },
    {
      datakey: 'risk',
      sortkey: 'risk',
      filterkey: 'risk',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__RISK',
        defaultMessage: 'Rizikový OP',
      }),
      width: 80,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 1,
      filterOrder: 11,
      visible: false,
    },
    {
      datakey: 'technicalPlaceSap',
      sortkey: 'technicalPlaceSap',
      filterkey: 'technicalPlaceSap',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__TECHNICAL_PLACE_SAP',
        defaultMessage: 'Technické miesto SAP',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 12,
      visible: false,
    },
    {
      datakey: 'standardTextKey',
      sortkey: 'standardTextKey',
      filterkey: 'standardTextKey',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__STANDARD_TEXT_KEY',
        defaultMessage: 'Kľúč štandardného textu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 13,
      visible: false,
    },
    {
      datakey: 'customerPhoneNumber',
      sortkey: 'customerPhoneNumber',
      filterkey: 'customerPhoneNumber',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CUSTOMER_PHONE_NUMBER',
        defaultMessage: 'Telefónne číslo zákazníka',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 14,
      visible: false,
    },
    {
      datakey: 'callId',
      sortkey: 'callId',
      filterkey: 'callId',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CALL_ID',
        defaultMessage: 'ID úlohy',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 15,
      visible: false,
    },
    {
      datakey: 'equipmentId',
      sortkey: 'equipmentId',
      filterkey: 'equipmentId',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__EQUIPMENT_ID',
        defaultMessage: 'Popis vybavení - id',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 16,
      visible: false,
    },
    {
      datakey: 'equipmentText',
      sortkey: 'equipmentText',
      filterkey: 'equipmentText',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__EQUIPMENT_TEXT',
        defaultMessage: 'Popis vybavení - text',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 17,
      visible: false,
    },
    {
      datakey: 'customerName',
      sortkey: 'customerName',
      filterkey: 'customerName',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CUSTOMER_NAME',
        defaultMessage: 'Meno zákazníka',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 18,
      visible: false,
    },
    {
      datakey: 'consumptionPlaceNumber',
      sortkey: 'consumptionPlaceNumber',
      filterkey: 'consumptionPlaceNumber',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CONSUMPTION_PLACE_NUMBER',
        defaultMessage: 'Číslo miesta spotreby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 19,
      visible: false,
    },
    {
      datakey: 'hdoCode',
      sortkey: 'hdoCode',
      filterkey: 'hdoCode',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__HDO_CODE',
        defaultMessage: 'Kód HDO',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 20,
      visible: false,
    },
    {
      datakey: 'mainCircuitBreaker',
      sortkey: 'mainCircuitBreaker',
      filterkey: 'mainCircuitBreaker',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__MAIN_CIRCUIT_BRAKER',
        defaultMessage: 'Hlavný istič',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 21,
      visible: false,
    },
    {
      datakey: 'oldTariffType',
      sortkey: 'oldTariffType',
      filterkey: 'oldTariffType',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__OLD_TARIFF_TYPE',
        defaultMessage: 'Platný typ tarifu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 22,
      visible: false,
    },
    {
      datakey: 'newTariffType',
      sortkey: 'newTariffType',
      filterkey: 'newTariffType',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__NEW_TARIFF_TYPE',
        defaultMessage: 'Požadovaný typ tarifu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 23,
      visible: false,
    },
    {
      datakey: 'oldTariffication',
      sortkey: 'oldTariffication',
      filterkey: 'oldTariffication',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__OLD_TARIFFICATION',
        defaultMessage: 'Platná tarifikácia',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 24,
    },
    {
      datakey: 'newTarification',
      sortkey: 'newTarification',
      filterkey: 'newTarification',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__NEW_TARIFFICATION',
        defaultMessage: 'Požadovaná tarifikácia',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 25,
      visible: false,
    },
    {
      datakey: 'consumptionPointTypeDescription',
      sortkey: 'consumptionPointTypeDescription',
      filterkey: 'consumptionPointTypeDescription',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CONSUMPTION_POINT_TYPE_DESCRIPTION',
        defaultMessage: 'Popis druhu miesta spotreby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 26,
      visible: false,
    },
    {
      datakey: 'meterSerialId',
      sortkey: 'meterSerialId',
      filterkey: 'meterSerialId',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__METER_SERIAL_ID',
        defaultMessage: 'Sériové číslo ELM',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 27,
      visible: false,
    },
    {
      datakey: 'elmMaterialType',
      sortkey: 'elmMaterialType',
      filterkey: 'elmMaterialType',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__ELM_MATERIAL_TYPE',
        defaultMessage: 'Materiál - typ ELM',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 28,
      visible: false,
    },
    {
      datakey: 'integratedHdoCode',
      sortkey: 'integratedHdoCode',
      filterkey: 'integratedHdoCode',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__INTEGRATED_HDO_CODE',
        defaultMessage: 'Integrovaný kód HDO',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 29,
      visible: false,
    },
    {
      datakey: 'billingArea',
      sortkey: 'billingArea',
      filterkey: 'billingArea',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__BILLING_AREA',
        defaultMessage: 'Fakturačná oblasť',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 30,
      visible: false,
    },
    {
      datakey: 'vgk1',
      sortkey: 'vgk1',
      filterkey: 'vgk1',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__VGK1',
        defaultMessage: 'VKG, 1 pole - Označenie miesta prístroja',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 31,
      visible: false,
    },
    {
      datakey: 'vgk2',
      sortkey: 'vgk2',
      filterkey: 'vgk2',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__VGK2',
        defaultMessage: 'VKG, 2 pole - Dodatok k stanovisku',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 32,
      visible: false,
    },
    {
      datakey: 'vgk3',
      sortkey: 'vgk3',
      filterkey: 'vgk3',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__VGK3',
        defaultMessage: 'VKG, 3 pole - Dlhý text k tech. miestu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 33,
      visible: false,
    },
    {
      datakey: 'customerVulnerable',
      sortkey: 'customerVulnerable',
      filterkey: 'customerVulnerable',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CUSTOMER_VULNERABLE',
        defaultMessage: 'Zraniteľný zákazník',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 1,
      filterOrder: 34,
      visible: false,
    },
    {
      datakey: 'customerVip',
      sortkey: 'customerVip',
      filterkey: 'customerVip',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CUSTOMER_VIP',
        defaultMessage: 'VIP zákazník',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 1,
      filterOrder: 35,
      visible: false,
    },
    {
      datakey: 'ownerTo',
      sortkey: 'ownerTo',
      filterkey: 'equipownerTomentText',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__OWNER_TO',
        defaultMessage: 'Vlastník TO',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 1,
      filterOrder: 36,
      visible: false,
    },
    {
      datakey: 'numberOfTaskFailure',
      sortkey: 'numberOfTaskFailure',
      filterkey: 'numberOfTaskFailure',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__NUMBER_OF_TASK_FAILURE',
        defaultMessage: 'Počet porúch na danom TM/ OM za posledné 3 mesiace',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 1,
      filterOrder: 37,
      visible: false,
    },
    {
      datakey: 'startTime',
      sortkey: 'startTime',
      filterkey: 'startTime',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__START_TIME',
        defaultMessage: 'Termín realizácie od',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterLocalDateTimeCell,
      filterGroup: 2,
      filterOrder: 0,
      visible: false,
    },
    {
      datakey: 'endTime',
      sortkey: 'endTime',
      filterkey: 'endTime',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__END_TIME',
        defaultMessage: 'Termín realizácie do',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterLocalDateTimeCell,
      filterGroup: 2,
      filterOrder: 1,
      visible: false,
    },
    {
      datakey: 'duration',
      sortkey: 'duration',
      filterkey: 'duration',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__DURATION',
        defaultMessage: 'Trvanie',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: DurationFilterComponent,
      filterGroup: 2,
      filterOrder: 2,
      valueMapper: durationColumnMapper,
    },
    {
      datakey: 'durationSap',
      sortkey: 'durationSap',
      filterkey: 'durationSap',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__DURATION_SAP',
        defaultMessage: 'Trvanie (SAP)',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: DurationFilterComponent,
      filterGroup: 2,
      filterOrder: 2,
      valueMapper: durationColumnMapper,
    },
    {
      datakey: 'earlyStart',
      sortkey: 'earlyStart',
      filterkey: 'earlyStart',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__EARLY_START',
        defaultMessage: 'Termín stretnutia od',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterLocalDateTimeCell,
      filterGroup: 2,
      filterOrder: 3,
      visible: false,
    },
    {
      datakey: 'dueDate',
      sortkey: 'dueDate',
      filterkey: 'dueDate',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__DUE_DATE',
        defaultMessage: 'Termín stretnutia do',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 4,
      visible: false,
    },
    {
      datakey: 'lateStart',
      sortkey: 'lateStart',
      filterkey: 'lateStart',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__LATE_START',
        defaultMessage: 'Začať plánovať najneskôr od',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 5,
      visible: false,
    },
    {
      datakey: 'travelPrevious',
      sortkey: 'travelPrevious',
      filterkey: 'travelPrevious',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__TRAVEL_PREVIOUS',
        defaultMessage: 'Prejazd k predchádzajúcemu',
      }),
      width: 100,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 2,
      filterOrder: 6,
      visible: false,
    },
    {
      datakey: 'travelNext',
      sortkey: 'travelNext',
      filterkey: 'travelNext',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__TRAVEL_NEXT',
        defaultMessage: 'Prejazd k nasledujúcemu',
      }),
      width: 100,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 2,
      filterOrder: 7,
      visible: false,
    },
    {
      datakey: 'previousOperation',
      sortkey: 'previousOperation.id',
      filterkey: 'previousOperation.id',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__PREVIOUS_OPERATION',
        defaultMessage: 'Predchádzajúci operácia',
      }),
      width: 100,
      CellComponent: function TextCell({ value, ...props }) {
        return <TableCells.TextCell value={value?.workOrderId} {...props} />;
      },
      sortable: true,
      filterable: true,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useWorkOrders,
          (wo) => wo.label ?? ''
        ),
      filterGroup: 2,
      filterOrder: 8,
      valueMapper: TableCells.dictionaryColumnMapper,
      visible: false,
    },
    {
      datakey: 'nextOperation',
      sortkey: 'nextOperation.id',
      filterkey: 'nextOperation.id',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__NEXT_OPERATION',
        defaultMessage: 'Nasledujúca operácia',
      }),
      width: 100,
      CellComponent: function TextCell({ value, ...props }) {
        return <TableCells.TextCell value={value?.workOrderId} {...props} />;
      },
      sortable: true,
      filterable: true,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useWorkOrders,
          (wo) => wo.label ?? ''
        ),
      filterGroup: 2,
      filterOrder: 9,
      valueMapper: TableCells.dictionaryColumnMapper,
      visible: false,
    },
    {
      datakey: 'dispatchDate',
      sortkey: 'dispatchDate',
      filterkey: 'dispatchDate',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__DISPATCH_DATE',
        defaultMessage: 'DispatchDate',
      }),
      width: 100,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterLocalDateTimeCell,
      filterGroup: 2,
      filterOrder: 10,
    },
    {
      datakey: 'address.city',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CITY',
        defaultMessage: 'Mesto',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.city',
      filterable: true,
      filterkey: 'address.city',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 0,
      visible: false,
    },
    {
      datakey: 'address.street',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__STREET',
        defaultMessage: 'Ulica',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.street',
      filterable: true,
      filterkey: 'address.street',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 1,
      visible: false,
    },
    {
      datakey: 'address.houseNumber',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__HOUSE_NUMBER',
        defaultMessage: 'Číslo popisné',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.houseNumber',
      filterable: true,
      filterkey: 'address.houseNumber',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 2,
      visible: false,
    },
    {
      datakey: 'address.postCode',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__POST_CODE',
        defaultMessage: 'PSČ',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.postCode',
      filterable: true,
      filterkey: 'address.postCode',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 3,
      visible: false,
    },
    {
      datakey: 'address.building',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__BUILDING',
        defaultMessage: 'Budova',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.building',
      filterable: true,
      filterkey: 'address.building',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 4,
      visible: false,
    },
    {
      datakey: 'address.additionalInfo',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__ADDITIONAL_INFO',
        defaultMessage: 'Dodatok',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'address.additionalInfo',
      filterable: true,
      filterkey: 'address.additionalInfo',
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 3,
      filterOrder: 5,
      visible: false,
    },
    {
      datakey: 'latitude',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__LATITUDE',
        defaultMessage: 'Zemepisná šírka',
      }),
      width: 150,
      sortkey: 'latitude',
      filterkey: 'latitude',
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 3,
      filterOrder: 6,
      visible: false,
    },
    {
      datakey: 'longitude',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__LONGITUDE',
        defaultMessage: 'Zemepisná dĺžka',
      }),
      width: 150,
      sortkey: 'longitude',
      filterkey: 'longitude',
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 3,
      filterOrder: 7,
      visible: false,
    },
    {
      datakey: 'certifications',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__CERTIFICATIONS',
        defaultMessage: 'Požadované kvalifikácie',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'certifications.concatenated',
      filterable: true,
      filterkey: 'certifications.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useCertifications
        ),
      filterGroup: 4,
      filterOrder: 0,
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      visible: false,
    },
    {
      datakey: 'mobileUnitSize',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__MOBILE_UNIT_SIZE',
        defaultMessage: 'Požadovaná veľkosť posádky',
      }),
      width: 150,
      sortkey: 'mobileUnitSize',
      filterkey: 'mobileUnitSize',
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 4,
      filterOrder: 1,
      visible: false,
    },
    {
      datakey: 'sameMobileUnit',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__SAME_MOBILE_UNIT',
        defaultMessage: 'Dokončiť rovnakou posádkou',
      }),
      width: 150,
      sortkey: 'sameMobileUnit',
      filterkey: 'sameMobileUnit',
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 4,
      filterOrder: 2,
      visible: false,
    },
    {
      datakey: 'numberOfAffectedCustomers',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__NUMBER_OF_AFFECTED_CUSTOMERS',
        defaultMessage: 'Počet postihnutých OM',
      }),
      width: 150,
      sortkey: 'numberOfAffectedCustomers',
      filterkey: 'numberOfAffectedCustomers',
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterGroup: 5,
      filterOrder: 0,
      visible: false,
    },
    {
      datakey: 'shortDescription',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__SHORT_DESCRIPTION',
        defaultMessage: 'Krátky popis',
      }),
      width: 150,
      sortkey: 'shortDescription',
      filterkey: 'shortDescription',
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterTextCell,
      filterGroup: 5,
      filterOrder: 1,
      visible: false,
    },
    {
      datakey: 'expectedMaterial',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__COLUMN__EXPECTED_MATERIAL',
        defaultMessage: 'Očakávaný materiál',
      }),
      width: 150,
      sortkey: 'expectedMaterial',
      filterkey: 'expectedMaterial',
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      FilterComponent: FieldFilter.FilterBooleanCell,
      filterGroup: 5,
      filterOrder: 2,
      visible: false,
    },
  ];
}
